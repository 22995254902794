import React, {Component} from 'react';
import Carousel from './Carousel.jsx';
import Dropdown from './Dropdown.jsx';
import BlogPost from "./BlogPost.jsx";
import DelayLink from './Delay.jsx';
import TextOnlyComponent from "./TextOnlyComponent.jsx";
import {ReactComponent as LogoArrow} from '../Assets/arrow_left_white.svg';

var imagesArray = '';
var ImageRight = require('../Assets/cfe_logo_right.svg');
var ImageLeft = require('../Assets/cfe_logo_left.svg');

class SingleProjectList extends Component {
    constructor() {
        super();
        this.state = {
            builderSections: null,
            events: null,
            dropdownItemsArray: [[2019, 2019]]
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromBlog", 0);
        this.props.hideEventsNavigation(true);
        this.props.getWhiteNav();
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            this.props.hideLoader();
        }, 2800);

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/project/' + this.props.match.params.name, obj)
            .then(response => response.json())
            .then(data => {
                this.setState({builderSections: data.data});
                let years = data.data.Events.reduce((accumulated, current) => {
                    console.log(current);
                    let date = new Date(current.Date.date.split(" ")[0]).getUTCFullYear();
                    if (accumulated.indexOf(date) === -1) {
                        accumulated.push(date);
                    }
                    return accumulated;
                }, []).map(item => [item, item]);

                console.log(years);
                years.sort(function(a,b){return new Date(b[0]) - new Date(a[0])});
                this.setState({'dropdownItemsArray': years});
                this.setState({
                    'events': this.state.builderSections.Events.reduce((accumulated, current) => {
                        let date = new Date(current.Date.date.split(" ")[0]).getUTCFullYear();
                        if (date === new Date().getUTCFullYear()) {
                            accumulated.push(current);
                        }
                        return accumulated;
                    }, []).map(item => item)
                });
            })
            .catch(err => console.log(err));
        if (document.getElementsByClassName("drop-" + new Date().getUTCFullYear()).length > 0) {
            document.getElementsByClassName("drop-" + new Date().getUTCFullYear())[0].classList.add("active");
        }
    }

    getEventsForYear(year) {
        if (document.getElementsByClassName('dropdown-element active').length > 0) {
            document.getElementsByClassName('dropdown-element active')[0].classList.remove("active");
        }
        let events = this.state.builderSections.Events.reduce((accumulated, current) => {
            let date = new Date(current.Date.date).getUTCFullYear();
            if (date === year) {
                accumulated.push(current);
            }
            return accumulated;
        }, []).map(item => item)
        this.setState({'events': events});
        document.getElementsByClassName("drop-" + year)[0].classList.add("active");
    }

    updateDimensions() {
        if (window.innerWidth > 769) {
            var el = document.getElementById("mask-inner-year");
            if (el) {
                var left = window.innerWidth - 330;
                el.setAttribute("x", left + 'px');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        if (window.innerWidth < 1024) {
            this.props.getBlackNav();
        }
    }

    render() {
        let options = this.props.options;
        let months = {
            '01': "January", '02': "February", '03': "March", '04': "April", '05': "May", '06': "June",
            '07': "July", '08': "August", '09': "September", '10': "October", '11': "November", '12': "December"
        };
        let {builderSections, dropdownItemsArray, events} = this.state;
        return (
            <div className="single-project">
                {builderSections != null ?
                    <div className="single-project-title">{builderSections.Details.Title}</div> : ""}
                <div className="cutted-background"></div>
                <div className="row">
                    <div className="col-12 dropdown-wrapper-desktop">
                        <div className="svg-blog">
                            <svg className="masked-element-year" width="100%" height="350px">
                                <svg width="300%" height="350px">
                                    <rect className="rectangle-background" x="0" y="0" width="100%" height="350px"
                                          fill="white"/>
                                </svg>
                            </svg>
                            <div className="arrow-down"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-mask" width="100%">
                                <defs>
                                    <mask id="mask-year">
                                        <svg width="100%" height="350px" x="calc(100% - 330px)" id="mask-inner-year">
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-240px"
                                                   xlinkHref={ImageRight}>
                                            </image>
                                            <circle cx="175" cy="175" r="175" fill="white"/>
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-120px"
                                                   xlinkHref={ImageLeft}>
                                            </image>
                                        </svg>
                                    </mask>
                                </defs>
                            </svg>
                        </div>
                        {dropdownItemsArray !== null ? <Dropdown type="Year" items={dropdownItemsArray}
                                                                 clickFunction={this.getEventsForYear.bind(this)}/> : ''}
                    </div>
                </div>
                <div className="row">
                    {parseInt(sessionStorage.getItem("fromMap")) > 0 ?
                        <DelayLink to={parseInt(sessionStorage.getItem("fromMap")) > 1 ? "/projects-list" : "/projects"}
                                   delay={2000}
                                   onDelayStart={() => {
                                       'started'
                                   }}
                                   onDelayEnd={() => {
                                       'ended'
                                   }}>
                            <div className="blog-back-button"><LogoArrow></LogoArrow><span
                                className="back-button-label">Back</span></div>
                        </DelayLink>

                        : <div className="blog-back-button">
                            <button onClick={() => window.history.go(-1)}><LogoArrow></LogoArrow><span
                                className="back-button-label">Back</span>
                            </button>
                        </div>}
                </div>
                <div className="">
                    {builderSections !== null ?
                        builderSections.BuilderDetails.Layouts.map((item) => (
                            item.Widgets != null ?
                                item.Widgets.map((widget) => {
                                    switch (widget.Type) {
                                        case 4:
                                            imagesArray = widget.Options.media.map(item => [{
                                                url: this.props.getFullImage(item.pictureUrl),
                                                copyright: item.title,
                                                media: 0
                                            }]);
                                            return <div
                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                <div className="project-slider-section">
                                                    <Carousel className="single-project-carousel" number="1"
                                                              effect="fade"
                                                              hideSwipe="false"
                                                              images={imagesArray}></Carousel>
                                                </div>
                                            </div>;
                                        default:
                                            return '';

                                    }
                                }) : ''

                        )) : ''}
                    <div className="page row project-list-section">
                        {builderSections !== null ?
                            builderSections.BuilderDetails.Layouts.map((item) => (
                                item.Widgets != null ?
                                    item.Widgets.map((widget) => {
                                        switch (widget.Type) {
                                            case 19:
                                                return <div
                                                    className={`col-8 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                    {widget.Options.link != '' ?
                                                        <a href={widget.Options.link} target="_blank"><img
                                                            className="project-black-logo" alt='article'
                                                            src={this.props.getFullImage(widget.Options.pictureUrl)}/></a>
                                                        : <img className="project-black-logo" alt='article'
                                                               src={this.props.getFullImage(widget.Options.pictureUrl)}/>}
                                                </div>
                                            case 23 :
                                                return <TextOnlyComponent title={widget.Options.title}
                                                                          description={widget.Options.description}
                                                                          button={widget.Options.url ? 1 : ""}
                                                                          column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                          route={widget.Options.url}
                                                                          background={widget.Options.whiteBackground}
                                                                          buttonText={widget.Options.content}/>;
                                            default:
                                                return '';

                                        }
                                    }) : ''

                            )) : ''}
                        <div className="col-12">
                            <div className="row">
                                <div className="col-12 dropdown-wrapper">
                                    {dropdownItemsArray !== null ? <Dropdown type="Year" items={dropdownItemsArray}
                                                                             clickFunction={this.getEventsForYear.bind(this)}/> : ''}
                                </div>
                            </div>
                            {events !== null ?
                                events.map((item) => (
                                    <BlogPost linkOut='1' link={item.Details.Url} button="1"
                                              buttonText={options.event_button_text}
                                              image={'https://classicalfutures.eu/cdn/Media/Events/' + item.Picture}
                                              backgroundPosition={item.BackgroundPositionHorizontal + " " + item.BackgroundPositionVertical}
                                              margin={item.BackgroundPositionHorizontal == 'right' ? 65 : item.BackgroundPositionHorizontal == 'left' ? 30 : 40}
                                              title={item.Details.Title}
                                              copyright={item.Details.Copyright != null ? item.Details.Copyright : ''}
                                              description={item.Details.Description}
                                              date={(item.Date.date.split(" ")[0]).split("-")[2] + ' ' + months[(item.Date.date.split(" ")[0]).split("-")[1]] + " " + (item.Date.date.split(" ")[0]).split("-")[0]}></BlogPost>
                                )) : ''}
                        </div>



                    </div>
                </div>
            </div>
        )
    }
}


export default SingleProjectList;