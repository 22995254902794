import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import NavLink from './NavLink.jsx';

var closeIcon = require('../Assets/close.png');

class CustomModal extends Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        if (localStorage.getItem("cookies") == 1) {
            this.state = {
                show: false
            };
        } else {
            this.state = {
                show: true,
            };
        }
    }

    handleClose() {
        this.setState({show: false});
    }

    handleShow() {
        this.setState({show: true});
    }

    acceptCookies() {
        localStorage.setItem("cookies", 1);
        this.handleClose();
    }

    render() {
        let {show} = this.state;

        return (
            <div>
                <Modal.Dialog className={show ? 'modal-active' : ''}>
                    <Modal.Body>
                        <p>By continuing to browse the site you are agreeing to our use of cookies and our privacy
                            policy</p>
                        <div className="button-wrapper">
                            <Button variant="secondary" onClick={this.acceptCookies.bind(this)}>Accept</Button>
                            <NavLink data-href="policy" to="/policy"><Button variant="secondary" onClick={this.handleClose}>More
                                info</Button></NavLink></div>
                        <Button className="close-button" onClick={this.handleClose}><img className="close-icon" alt="close"
                                                                                         src={closeIcon}></img></Button>
                    </Modal.Body>
                </Modal.Dialog>
            </div>

        );
    }
}


export default CustomModal;

