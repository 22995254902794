import React, {Component} from 'react';
import TextOnlyComponent from "./TextOnlyComponent";
import Person from "./Person.jsx";
import ReactPlayer from "react-player";
import Video from "./Video.jsx";
import {withRouter} from "react-router-dom";
class General extends Component {
    constructor() {
        super();
        this.state = {
            builderSections: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        var data =  JSON.parse(this.props.data);
        this.setState({builderSections: data});
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);
        sessionStorage.setItem("fromBlog", 0);

    }

    render() {
        let {builderSections} = this.state;
        return (
            <div>
                <div className="policy-section page">
                    <div className="row">
                        <div className="col-12">
                            {builderSections !== null ?
                                <h1 className="policy-title">{builderSections.Details[0].Title}</h1> : ''}
                        </div>
                    </div>
                    <div className="">
                        {builderSections !== null ?
                            builderSections.BuilderDetails.Layouts.map((item) => (
                                item.Widgets != null ?
                                    item.Widgets.map((widget) => {
                                        switch (widget.Type) {
                                            case 19:
                                                return <div
                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                    <img src={widget.Options.pictureUrl}/></div>;
                                            case 23:
                                                return <TextOnlyComponent title={widget.Options.title}
                                                                          description={widget.Options.description}
                                                                          button={widget.Options.url ? 1 : ""}
                                                                          column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                          route={widget.Options.url}
                                                                          background={widget.Options.whiteBackground}
                                                                          buttonText={widget.Options.content}/>;
                                            case 10:
                                                return <Person name={widget.Options.name}
                                                               description={widget.Options.description}
                                                               position={widget.Options.position}
                                                               image={widget.Options.pictureUrl ? this.props.getFullImage(widget.Options.pictureUrl) : ""}></Person>;
                                            case 16:
                                                return <div className="row">
                                                    <div
                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                        <div className="video-wrapper">
                                                            <ReactPlayer width="100%" url={widget.Options.content}/>
                                                        </div>
                                                    </div>
                                                </div>;
                                            case 23:
                                                return <TextOnlyComponent title={widget.Options.title}
                                                                          description={widget.Options.description}
                                                                          button={widget.Options.url ? 1 : ""}
                                                                          column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                          background={widget.Options.whiteBackground}
                                                                          buttonText={widget.Options.content}/>;
                                            case 25:
                                                if (widget.Options.videoLink) {
                                                    return <div>
                                                        <div className="logo-section">
                                                            <div className="row">
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <div className="small-skewed-box"></div> : ''}
                                                                    <div className="logo-skewed-clear">
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <img className="logo-about wow slideInRight"
                                                                                 data-wow-duration="2s" alt="Logo"
                                                                                 src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                            <div
                                                                                className="section-title">{widget.Options.title}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="video-section video-mobile">
                                                            <div className="row">
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 video-text">
                                                                            <div className="small-skewed-box"></div>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                        </div>
                                                                        <div
                                                                            className="col-md-7 col-12 wow slideInRight"
                                                                            data-wow-duration="2s">
                                                                            <Video
                                                                                url={widget.Options.videoLink}></Video>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        ;
                                                } else {
                                                    return <div className="video-section">
                                                        <div className="logo-section">
                                                            <div className="row">
                                                                <div className="col-md-4 col-0"></div>
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <div className="small-skewed-box"></div> : ''}
                                                                    <div className="logo-skewed-clear">
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <img className="logo-about wow slideInRight"
                                                                                 data-wow-duration="2s" alt="Logo"
                                                                                 src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                            <div
                                                                                className="section-title">{widget.Options.title}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                <div className="row">
                                                                    <div className="col-md-5 col-12 video-text">
                                                                        <div className="small-skewed-box"></div>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                    </div>
                                                                    <div className="col-md-7 col-12">
                                                                        <div className="image-wrapper">
                                                                            <div
                                                                                className="article-image wow slideInRight"
                                                                                data-wow-duration="2s"
                                                                                style={{backgroundImage: `url( ${this.props.getFullImage(widget.Options.media[0].pictureUrl)})`}}></div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        ;
                                                }
                                            default:
                                                return '';

                                        }
                                    }) : ''

                            )) : ''}
                    </div>
                </div>
            </div>

        );
    }
}


export default withRouter(General);

