import React, {Component} from "react";
import Video from "./Video.jsx"
import TextOnlyComponent from "./TextOnlyComponent.jsx";
import Person from "./Person";
import ReactPlayer from "react-player";

var ImageCenter = require('../Assets/cfe_logo_center.svg');
var ImageRight = require('../Assets/cfe_logo_right.svg');
var ImageLeft = require('../Assets/cfe_logo_left.svg');


var transformElement = (transform, el) => {
    el.setAttribute("x", transform);
};
var isP = 1;
var leftMobile = window.innerWidth / 2 - 103 - 15;

class About extends Component {

    constructor() {
        super();
        var width = window.innerWidth;
        var circleWidth = 95;
        var rectWidth = '2000px';
        if (window.innerWidth > 769) {
            width = window.innerWidth * 66.6 / 100;
            circleWidth = 206;
            rectWidth = '100%';
        }
        this.state = {
            builderSections: null,
            width: width,
            circleWidth: circleWidth,
            rectWidth: rectWidth
        }
    }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromBlog", 0);
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);

        window.addEventListener("resize", this.updateDimensions.bind(this));
        //get sections from API
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/page/about', obj)
            .then(response => response.json())
            .then(data => this.setState({builderSections: data.data})).then(() =>
            this.updateDimensions()).then(() => this.props.showFooter()).catch(err => console.log(err));

        this.updateDimensions();
    }

    scrollAbout() {
        var scrollPos = 0;
        var svg = document.getElementById("full-width");
        if (svg != null) {
            svg.setAttribute("width", "2000px");
        }
        var down = document.body.getBoundingClientRect().top / 1.8;
        document.getElementsByClassName("we-are-section")[0].classList.remove("scrollable")
        document.getElementsByClassName("we-are-section")[0].style.top = 'calc(104px + 10vh)';
        var downArrow = document.getElementsByClassName("arrow-down")[0];
        let PictureLeft = document.getElementById("animation-about-right");
        let PictureRight = document.getElementById("animation-about-left");

        if (down * (-1) < (window.innerWidth * 2 / 12)) {
            downArrow.classList.remove("hidden");
            document.getElementById("about-mobile-titles").classList.remove("visible");
        } else {
            downArrow.classList.add("hidden");
            document.getElementById("about-mobile-titles").classList.add("visible");
        }
        var fromTop = window.innerWidth * 2 / 6;
        if (window.innerWidth > 769) {
            fromTop = window.innerWidth * 2 / 9;
            var down = document.body.getBoundingClientRect().top / 1.5;
        }

        if (down * (-1) < fromTop) {
            if (document.getElementsByClassName("mobile_nav").length > 0) {
                document.getElementsByClassName("mobile_nav")[0].classList.add("fixed");
            }
            document.getElementsByClassName("about-sections")[0].classList.add("hidden");
            document.getElementsByClassName("about-sections")[1].classList.add("hidden");
            document.getElementsByClassName("circle-image-1")[0].classList.add("image-hidden");
            document.getElementsByClassName("circle-image-2")[0].classList.add("image-hidden");
            document.getElementsByClassName("circle-image-3")[0].classList.add("image-hidden");


            if ((document.body.getBoundingClientRect()).top === 0) {
                transformElement(0, PictureRight);
                transformElement(0, PictureLeft);
                document.getElementsByClassName("about-sections")[0].classList.add("hidden");
                document.getElementsByClassName("about-sections")[1].classList.add("hidden");

            }
            if ((document.body.getBoundingClientRect()).top > scrollPos) {
                transformElement(-down, PictureLeft);
                transformElement(down, PictureRight);

            } else {
                transformElement(-down, PictureLeft);
                transformElement(down, PictureRight);
            }

            document.getElementsByClassName("we-are-section")[0].classList.remove("hidden");

            var page = document.getElementsByClassName("page")[0];
            page.querySelector(".cutted-background").classList.remove("hidden");
            isP = 1;
        } else {
            if (document.getElementsByClassName("mobile_nav").length > 0) {
                document.getElementsByClassName("mobile_nav")[0].classList.remove("fixed");
            }
            if (isP) {
                document.getElementsByClassName("scrollable-holder")[0].style.paddingTop = 'calc(104px + 10vh + ' + -1 * document.body.getBoundingClientRect().top + 'px)';
                isP = 0;
                setTimeout(function () {
                    document.getElementsByClassName("about-sections")[0].classList.remove("hidden");
                    document.getElementsByClassName("about-sections")[1].classList.remove("hidden");
                }, 300);

            }
            if (window.innerWidth > 769) {
                document.getElementsByClassName("circle-image-1")[0].classList.remove("image-hidden");
                document.getElementsByClassName("circle-image-2")[0].classList.remove("image-hidden");
                document.getElementsByClassName("circle-image-3")[0].classList.remove("image-hidden");
            } else {
                document.getElementsByClassName("we-are-section")[0].classList.add("hidden");
                var page = document.getElementsByClassName("page")[0];
                page.querySelector(".cutted-background").classList.add("hidden");

            }

            document.getElementsByClassName("we-are-section")[0].classList.add("scrollable");

        }
        if (svg != null) {
            svg.setAttribute("width", "2000px");
        }
    }

    updateDimensions() {
        var el = document.getElementById("mask2-inner");
        var svg = document.getElementById("full-width");
        if (svg != null) {
            svg.setAttribute("width", "2000px");
        }
        if (window.innerWidth > 769) {
            var left = window.innerWidth / 3 - 103;
            if (el != null) {
                el.setAttribute("x", left + 'px');
            }
        } else {

            var el1 = document.getElementById("mask-inner-1");
            var el2 = document.getElementById("mask-inner-2");
            var el3 = document.getElementById("mask-inner-3");
            var leftMobile = window.innerWidth / 2 - 103 - 15;
            if (el1 != null) {
                el1.setAttribute("x", leftMobile + 'px');
            }
            if (el2 != null) {
                el2.setAttribute("x", leftMobile + 'px');
            }
            if (el3 != null) {
                el3.setAttribute("x", leftMobile + 'px');
            }

            if (el != null) {
                el.setAttribute("x", window.innerWidth / 2 - 47 + 'px');
            }
            var ele = document.getElementById("circle-backgrounds");
            if (ele != null) {
                ele.setAttribute("x", leftMobile + 'px');
            }

        }
        window.addEventListener('scroll', this.scrollAbout);
    }

    componentWillReceiveProps(nextProps) {

    }

    componentDidUpdate(prevProps, prevState) {
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollAbout);
        window.removeEventListener("resize", this.updateDimensions);
        if (document.getElementsByClassName("mobile_nav") && window.innerWidth < 1000) {
        document.getElementsByClassName("mobile_nav")[0].classList.remove("fixed");
        }
    }

    render() {
        let {builderSections, width, circleWidth, rectWidth} = this.state;
        return (

            <div className="scrollable-holder page">
                <div className="cutted-background"></div>
                <div className="row">
                    <div className="col-12">
                        {builderSections !== null ?
                            builderSections.BuilderDetails.Layouts.map((item) => (
                                item.Widgets != null ?
                                    item.Widgets.map((widget) => {
                                        switch (widget.Type) {
                                            case 24:
                                                return <div>
                                                    <div className='we-are-section' style={{width: width + 'px'}}>
                                                        <div className="box-wrapper about-box-wrapper">
                                                            <div className="skewed-box">
                                                            </div>
                                                        </div>
                                                        <div
                                                            className="section-title about-title text-center">{widget.Options.title}</div>

                                                        <div className="svg-about">
                                                            <svg className="masked-element-about" width="2000px"
                                                                 height="206px">
                                                                <svg id="full-width" width="2000px" height="206px"
                                                                     x={circleWidth > 100 ? '0%' : "0"}>
                                                                    <rect className="rectangle-background" x="0"
                                                                          y="0"
                                                                          width={rectWidth} height="206px"
                                                                          fill="black"/>
                                                                    <svg id="circle-backgrounds" x="0" y="0"
                                                                         width="100%" height="206px">
                                                                        <image preserveAspectRatio="none"
                                                                               className="circle-image-1 image-hidden"
                                                                               width="30%"
                                                                               height="206px"
                                                                               xlinkHref={this.props.getFullImage(widget.Options.pictureUrl1)}></image>
                                                                        <image preserveAspectRatio="none"
                                                                               className="circle-image-2 image-hidden"
                                                                               x="30%"
                                                                               width="30%" height="206px"
                                                                               xlinkHref={this.props.getFullImage(widget.Options.pictureUrl2)}></image>
                                                                        <image preserveAspectRatio="none"
                                                                               className="circle-image-3 image-hidden"
                                                                               x="60%"
                                                                               width="30%" height="206px"
                                                                               xlinkHref={this.props.getFullImage(widget.Options.pictureUrl3)}></image>
                                                                    </svg>
                                                                </svg>
                                                            </svg>
                                                            <div className="about-mobile-titles"
                                                                 id="about-mobile-titles">
                                                                <span>Venues</span>
                                                                <span>Artist</span>
                                                                <span>Audiences & Communities</span>
                                                            </div>
                                                            <div className="arrow-down"></div>
                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                 className="svg-mask"
                                                                 width="100%">
                                                                <defs>
                                                                    <mask id="mask2">

                                                                        <svg width="100%" height="206px"
                                                                             x="calc(50% - 206px)"
                                                                             id="mask2-inner">
                                                                            <image className="image-chunk"
                                                                                   id="animation-about-right"
                                                                                   width={circleWidth + 'px'}
                                                                                   height={circleWidth + 'px'}
                                                                                   xlinkHref={ImageLeft}>
                                                                            </image>
                                                                            <image width={circleWidth + 'px'}
                                                                                   height={circleWidth + 'px'}
                                                                                   xlinkHref={ImageCenter}></image>
                                                                            <image className="image-chunk"
                                                                                   id="animation-about-left"
                                                                                   width={circleWidth + 'px'}
                                                                                   height={circleWidth + 'px'}
                                                                                   xlinkHref={ImageRight}></image>
                                                                        </svg>
                                                                    </mask>
                                                                </defs>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="about-sections hidden circles">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="row">
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="column-image">
                                                                            <svg
                                                                                className="masked-element-about mask-1"
                                                                                width="100%"
                                                                                height="206px">
                                                                                <svg width={rectWidth} height="206px">
                                                                                    <svg id="circle-backgrounds"
                                                                                         x="0"
                                                                                         y="0" width="100%"
                                                                                         height={circleWidth + 'px'}>
                                                                                        <image
                                                                                            preserveAspectRatio="none"
                                                                                            className="circle-image-1 image-hidden"
                                                                                            width="100%"
                                                                                            height="206px"
                                                                                            xlinkHref={this.props.getFullImage(widget.Options.pictureUrl1)}></image>
                                                                                    </svg>
                                                                                </svg>
                                                                            </svg>
                                                                            <div className="arrow-down"></div>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="svg-mask"
                                                                                 width="100%">
                                                                                <defs>
                                                                                    <mask id="circle-background1">
                                                                                        <svg width="100%"
                                                                                             height="206px"
                                                                                             x={leftMobile + "px"}
                                                                                             id="mask-inner-1">
                                                                                            <image
                                                                                                className="image-chunk"
                                                                                                width="206px"
                                                                                                height="206px"
                                                                                                xlinkHref={ImageRight}>
                                                                                            </image>
                                                                                        </svg>
                                                                                    </mask>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="animation-text"
                                                                             dangerouslySetInnerHTML={{__html: widget.Options.description1}}></div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="column-image">
                                                                            <svg
                                                                                className="masked-element-about mask-2"
                                                                                width="100%"
                                                                                height="206px">
                                                                                <svg width={rectWidth} height="206px">
                                                                                    <svg id="circle-backgrounds"
                                                                                         x="0"
                                                                                         y="0" width="100%"
                                                                                         height="206px">
                                                                                        <image
                                                                                            preserveAspectRatio="none"
                                                                                            className="circle-image-1 image-hidden"
                                                                                            width="100%"
                                                                                            height="206px"
                                                                                            xlinkHref={this.props.getFullImage(widget.Options.pictureUrl2)}></image>
                                                                                    </svg>
                                                                                </svg>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="svg-mask"
                                                                                 width="100%">
                                                                                <defs>
                                                                                    <mask id="circle-background2">
                                                                                        <svg width="100%"
                                                                                             height="206px"
                                                                                             x={leftMobile + "px"}
                                                                                             id="mask-inner-2">
                                                                                            <image
                                                                                                className="image-chunk"
                                                                                                width="206px"
                                                                                                height="206px"
                                                                                                xlinkHref={ImageCenter}>
                                                                                            </image>
                                                                                        </svg>
                                                                                    </mask>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="animation-text"
                                                                             dangerouslySetInnerHTML={{__html: widget.Options.description2}}></div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12">
                                                                        <div className="column-image">
                                                                            <svg
                                                                                className="masked-element-about mask-3"
                                                                                width="100%"
                                                                                height="206px">
                                                                                <svg width={rectWidth} height="206px">
                                                                                    <svg id="circle-backgrounds"
                                                                                         x="0"
                                                                                         y="0" width="100%"
                                                                                         height="206px">
                                                                                        <image
                                                                                            preserveAspectRatio="none"
                                                                                            className="circle-image-1 image-hidden"
                                                                                            width="100%"
                                                                                            height="206px"
                                                                                            xlinkHref={this.props.getFullImage(widget.Options.pictureUrl3)}></image>
                                                                                    </svg>
                                                                                </svg>
                                                                            </svg>
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                 className="svg-mask"
                                                                                 width="100%">
                                                                                <defs>
                                                                                    <mask id="circle-background3">
                                                                                        <svg width="100%"
                                                                                             height="206px"
                                                                                             x={leftMobile + "px"}
                                                                                             id="mask-inner-3">
                                                                                            <image
                                                                                                className="image-chunk"
                                                                                                width="206px"
                                                                                                height="206px"
                                                                                                xlinkHref={ImageLeft}>
                                                                                            </image>
                                                                                        </svg>
                                                                                    </mask>
                                                                                </defs>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="animation-text"
                                                                             dangerouslySetInnerHTML={{__html: widget.Options.description3}}></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>;
                                            default:
                                                return '';

                                        }
                                    }) : ''

                            )) : ''}

                    </div>
                </div>
                <div className="about-sections hidden">
                    {builderSections !== null ?
                        builderSections.BuilderDetails.Layouts.map((item) => (
                            item.Widgets != null ?
                                item.Widgets.map((widget) => {
                                    switch (widget.Type) {
                                        case 10:
                                            return <Person name={widget.Options.name}
                                                           description={widget.Options.description}
                                                           position={widget.Options.position}
                                                           image={widget.Options.pictureUrl ? this.props.getFullImage(widget.Options.pictureUrl) : ""}></Person>;
                                        case 19:
                                            return <img src={widget.Options.pictureUrl}/>;
                                        case 16:
                                            return <div className="row">
                                                <div
                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                    <div className="video-wrapper">
                                                        <ReactPlayer width="100%" url={widget.Options.content}/>
                                                    </div>
                                                </div>
                                            </div>;
                                        case 23:
                                            return <TextOnlyComponent title={widget.Options.title}
                                                                      description={widget.Options.description}
                                                                      button={widget.Options.url ? 1 : ""}
                                                                      column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                      background={widget.Options.whiteBackground}
                                                                      buttonText={widget.Options.content}/>;
                                        case 25:
                                            if (widget.Options.videoLink) {
                                                return <div>
                                                    <div className="logo-section">
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                {widget.Options.pictureUrl != '' ?
                                                                    <div className="small-skewed-box"></div> : ''}
                                                                <div className="logo-skewed-clear">
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <img className="logo-about wow slideInRight"
                                                                             data-wow-duration="2s" alt="Logo"
                                                                             src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                        <div
                                                                            className="section-title">{widget.Options.title}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="video-section video-mobile">
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                <div className="row">
                                                                    <div className="col-md-5 col-12 video-text">
                                                                        <div className="small-skewed-box"></div>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                    </div>
                                                                    <div
                                                                        className="col-md-7 col-12 wow slideInRight"
                                                                        data-wow-duration="2s">
                                                                        <Video
                                                                            url={widget.Options.videoLink}></Video>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    ;
                                            } else {
                                                return <div className="video-section">
                                                    <div className="logo-section">
                                                        <div className="row">
                                                            <div className="col-md-4 col-0"></div>
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                {widget.Options.pictureUrl != '' ?
                                                                    <div className="small-skewed-box"></div> : ''}
                                                                <div className="logo-skewed-clear">
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <img className="logo-about wow slideInRight"
                                                                             data-wow-duration="2s" alt="Logo"
                                                                             src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                        <div
                                                                            className="section-title">{widget.Options.title}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                            <div className="row">
                                                                <div className="col-md-5 col-12 video-text">
                                                                    <div className="small-skewed-box"></div>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                </div>
                                                                <div className="col-md-7 col-12 image-wrapper">

                                                                        <div
                                                                            className="article-image wow slideInRight"
                                                                            data-wow-duration="2s"
                                                                            style={{ backgroundImage: `url( ${ widget.Options.media ? this.props.getFullImage(widget.Options.media[0].pictureUrl) : ""})` }}></div>
                                                                    </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    ;
                                            }
                                        default:
                                            return '';

                                    }
                                }) : ''

                        )) : ''}

                </div>
            </div>

        )
    }

}


export default About;