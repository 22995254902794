import React, {Component} from "react";
import CustomScroll from 'react-custom-scroll';

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleMouseEvent = this.handleMouseEvent.bind(this);
        this.handleBlurEvent = this.handleBlurEvent.bind(this);
        this.hasFocus = this.hasFocus.bind(this);

        this.state = {
            show: false
        };
    }

    componentDidMount() {
        // handles mouse events like click and dblclick
        document.addEventListener('mouseup', this.handleMouseEvent);
        // handles tabbing out of
        this.dropdown.addEventListener('focusout', this.handleBlurEvent);
    }

    hasFocus(target) {
        // React ref callbacks pass `null` when a component unmounts, so guard against `this.dropdown` not existing
        if (!this.dropdown) {
            return false;
        }
        var dropdownHasFocus = false;
        var nodeIterator = document.createNodeIterator(this.dropdown, NodeFilter.SHOW_ELEMENT, null, false);
        var node;

        while (node === nodeIterator.nextNode()) {
            if (node === target) {
                dropdownHasFocus = true;
                break;
            }
        }

        return dropdownHasFocus;
    }

    handleBlurEvent(e) {
        /*  var dropdownHasFocus = this.hasFocus(e.relatedTarget);

         if (!dropdownHasFocus) {
         this.setState({
         show: false
         });
         }*/
    }

    handleMouseEvent(e) {
        /*  var dropdownHasFocus = this.hasFocus(e.target);

         if (!dropdownHasFocus) {
         this.setState({
         show: false
         });
         }*/
    }

    toggleDropdown() {
        this.setState({
            show: !this.state.show
        });
        let whiteCircle = document.getElementsByClassName("svg-blog")[0];
        whiteCircle.classList.toggle("opened");
    }

    render() {
        return (
            <div className={`dropdown mobile ${this.state.show ? 'show' : ''}`}
                 ref={(dropdown) => this.dropdown = dropdown}>
                <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={this.state.show}
                    onClick={this.toggleDropdown}>
                    {this.props.type}
                </button>

                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton">
                    <CustomScroll heightRelativeToParent="90%">
                        {this.props.items.map(item => (<li className={"drop-"+item[0] + " dropdown-element"}  onClick={() => {this.props.clickFunction(item[0])}} key={item[0]}>{item[1]}<hr></hr></li>))}
                    </CustomScroll>
                </div>

            </div>
        );
    }
}


export default Dropdown;