import React from 'react';
import {Route, withRouter} from "react-router-dom";
import {ReactComponent as Logo} from '../Assets/cfe_logo_final.svg';
import DelayLink from './Delay.jsx';
import {ReactComponent as LogoWhite} from '../Assets/logo_white.svg';
var NavIcon = require('../Assets/navigation.svg');
var NavIconWhite = require('../Assets/navigation-white.svg');
var CloseIcon = require('../Assets/exit.svg');
var Icon = require('../Assets/lupa.png');

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.location.pathname.indexOf("project/") != -1) {
            this.state = {
                windowWidth: window.innerWidth,
                mobileNavVisible: false,
                searchKey: null,
                menuItems: null,
                isProject: true
            };
        } else {
            this.state = {
                windowWidth: window.innerWidth,
                mobileNavVisible: false,
                searchKey: null,
                menuItems: null,
                isProject: false
            };
        }
        this.renderFullNavigation = this.renderFullNavigation.bind(this);
        this.hideMobileNav = this.hideMobileNav.bind(this);
    }

    handleResize() {
        this.setState({windowWidth: window.innerWidth});
    }

    search(event) {
        this.setState({
            searchKey: event.target.value
        });
    }

    componentDidMount() {
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',

            }
        };

        fetch('https://classicalfutures.eu/api/menus/1', obj)
            .then(response => response.json())
            .then(data => this.setState({menuItems: data.data})).catch(err => console.log(err));

        window.addEventListener('resize', this.handleResize.bind(this));
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize.bind(this));
    }

    hideMobileNav() {
        if (this.state.mobileNavVisible) {
            this.setState({mobileNavVisible: false})
            document.getElementsByClassName("page-content")[0].classList.remove("no-scroll");
        }
    }

    renderMobileNav(resourceslabel) {
        let {menuItems} = this.state;

            return (
                <div className={this.state.mobileNavVisible ? "sidebar-mobile active" : "sidebar-mobile"}>
                    <div
                        className={this.state.mobileNavVisible ? "cutted-background" : "cutted-background hidden"}></div>
                    <div className="sidebar-wrapper">
                        <DelayLink  hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                            to="/"
                            delay={2000}
                            onDelayStart={() => {
                                'started'
                            }}
                            onDelayEnd={() => {
                                'ended'
                            }}><Logo
                            className="logo"/></DelayLink>
                        <ul className="sidebar">
                            <li key="00" id="active-sidebar-link-mobile"></li>
                            {menuItems !== null ?
                                menuItems.map((item, index) => (
                                    <li key={"list-item-" + index}><DelayLink  hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                                        to={ item.Page.Slug == 'projects' ?  "/projects-list" : '/' + item.Page.Slug }
                                        delay={2000}
                                        onDelayStart={() => {
                                            'started'
                                        }}
                                        onDelayEnd={() => {
                                            'ended'
                                        }}>{item.Caption}</DelayLink></li>
                                )) : ''
                            }

                        </ul>
                        <div className="container sidebar-outline"></div>
                        <div className="input-field">
                            <div className="search-holder">
                                <DelayLink  hideSidebar={this.handleNavClick.bind(this)} data-href="resources" hideEventsNavigation={this.props.hideEventsNavigation}
                                                                            to={'/resources'}
                                                                            delay={2000}
                                                                            onDelayStart={() => {
                                                                                'started'
                                                                            }}
                                                                            onDelayEnd={() => {
                                                                                'ended'
                                                                            }}>{resourceslabel}</DelayLink>
                                <img className="loop-icon" alt="loop-icon" src={Icon}/>
                                <input type="text" name="search" id="search" value={this.state.searchKey}
                                       onChange={this.search.bind(this)} onKeyPress={this.onKeyPress.bind(this)}/>
                            </div>
                        </div>
                    </div>
                </div>
            );//Ovde html za mobile


    }

    handleNavClick() {
        if (!this.state.mobileNavVisible) {
            this.setState({mobileNavVisible: true});
            document.getElementsByClassName("page-content")[0].classList.add("no-scroll");
        } else {
            this.setState({mobileNavVisible: false});
            document.getElementsByClassName("page-content")[0].classList.remove("no-scroll");
        }
    }

    onKeyPress = (e) => {
        if (e.key === 'Enter' && this.state.searchKey !== "" && this.state.searchKey) {

            document.getElementById("overlayer").classList.add('active');
            setTimeout(() => {
                this.props.history.push('/search-results/' + this.state.searchKey);
                document.getElementsByClassName("close-icon")[0].click();
            }, 3500);

        }

    }

    renderFullNavigation(resourceslabel) {
        let {menuItems} = this.state;
        return (<div className="sidebar-wrapper">
            <div className="white-square"></div>
            <DelayLink hideSidebar={this.hideMobileNav.bind(this)}
                to="/" hideEventsNavigation={this.props.hideEventsNavigation}
                delay={2000}
                onDelayStart={() => {
                    'started'
                }}
                onDelayEnd={() => {
                    'ended'
                }}><Logo className="logo"/></DelayLink>
            <ul className="sidebar">
                <li key="00" id="active-sidebar-link"></li>
                {menuItems !== null ?
                    menuItems.map((item, index) => (
                        <li key={"list-item-" + index}><DelayLink
                            to={'/' + item.Page.Slug} hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                            delay={2000}
                            onDelayStart={() => {
                                'started'
                            }}
                            onDelayEnd={() => {
                                'ended'
                            }}>{item.Caption}</DelayLink></li>
                    )) : ''
                }

            </ul>
            <div className="container sidebar-outline"></div>
            <div className="input-field">
                <div className="search-holder">
                    <DelayLink data-href="resources"   hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                               to="/resources"
                               delay={2000}
                               onDelayStart={() => {
                                   'started'
                               }}
                               onDelayEnd={() => {
                                   'ended'
                               }}>{resourceslabel}</DelayLink>
                    <img className="loop-icon" alt="loop-icon" src={Icon}/>
                    <input type="text" name="search" id="search"
                           value={this.state.searchKey ? this.state.searchKey : ''}
                           onChange={this.search.bind(this)} onKeyPress={this.onKeyPress.bind(this)}/>
                </div>
            </div>
        </div>);
    }

    render() {
        let options = this.props.options;
        let {isProject} = this.state;
        let resourceslabel = "";
        if (options) {
            resourceslabel = this.props.options.resources_label;
        }

        if (this.state.windowWidth <= 1024) {
            return [
                <div className="mobile_nav">
                    <div className="nav-icon-holder">
                        {isProject ?
                            <DelayLink   hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                                to="/"
                                delay={2000}
                                onDelayStart={() => {
                                    'started'
                                }}
                                onDelayEnd={() => {
                                    'ended'
                                }}><LogoWhite
                                className="logo mobile-logo active"/></DelayLink> :
                            <DelayLink  hideSidebar={this.hideMobileNav.bind(this)} hideEventsNavigation={this.props.hideEventsNavigation}
                                to="/"
                                delay={2000}
                                onDelayStart={() => {
                                    'started'
                                }}
                                onDelayEnd={() => {
                                    'ended'
                                }}><Logo
                                className="logo mobile-logo active"/></DelayLink>}
                        {this.state.mobileNavVisible ?
                            <img onClick={this.handleNavClick.bind(this)} className="close-icon" alt="close-icon"
                                 src={CloseIcon}></img> : '' }
                            <div onClick={this.handleNavClick.bind(this)} style={{ opacity: `${this.state.mobileNavVisible ?  "0" : "1" }`}}><img
                                className="nav-icon black-nav-icon active" alt="nav-icon" src={NavIcon}></img><img
                                className="white-nav-icon nav-icon" alt="nav-icon" src={NavIconWhite}></img></div>

                    </div>
                    {this.renderMobileNav(resourceslabel)}
                </div>

            ];

        } else {
            return [
                this.renderFullNavigation(resourceslabel)
            ];
        }
    }
}

export default withRouter(Sidebar);
