import React, {Component} from 'react';

class Person extends Component {

    render() {
        return (
            <div className="person-section">
                <div className="row">
                    <div className="col-12">
                        <div className="blog-title">
                            <h1>{this.props.name ? this.props.name : ""}</h1>

                        </div>
                        <div className="text-wrapper">
                            <h5 className="article-title-desktop">{this.props.position ? this.props.position : ''}</h5>
                            <div className="">
                                {this.props.image != '' ? <div className="person-part small-skewed-box">
                                        <div className="person-img" style={{backgroundImage: `url( ${this.props.image})`}}></div>
                                    </div> :
                                    <div className="person-part  small-skewed-box no-image-box"></div>}
                                <div className="small-article-description"
                                     dangerouslySetInnerHTML={{__html: this.props.description}}></div>
                                <div className="small-skewed-box">
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>


        )
    }

}


export default Person;