import React, {Component} from 'react';
import {Button} from 'react-bootstrap';
import DelayLink from './Delay.jsx';


class TextOnlyComponent extends Component {
    render() {
        return (
            <div className={this.props.background === 1 ? `col-md-${this.props.column}  col-12 text-section  relative` : `text-section col-md-${this.props.column}  col-12  no-box`}>
                <div className="row">
                    <div>
                         {this.props.title ? <div className="section-title"><h1>{this.props.title}</h1></div> : ''}
                        <div className="box-wrapper">
                            {this.props.button ?
                                <DelayLink
                                           to={this.props.route}
                                           delay={2000}
                                           onDelayStart={() => {
                                               'started'
                                           }}
                                           onDelayEnd={() => {
                                               'ended'
                                           }}><Button className="box-button">
                                <div className="button-skewed-clear">
                                    {this.props.buttonText}
                                </div>
                            </Button>
                                    {this.props.background ? <div className="skewed-box"></div> : ''}</DelayLink>: ""}

                        </div>
                        {this.props.description ? <div className="description" dangerouslySetInnerHTML={{ __html: this.props.description}}></div> : ""}
                    </div>
                </div>
            </div>


        );
    }
}


export default TextOnlyComponent;

