import React, {Component} from 'react';
import ProjectCard from "./ProjectCard";
import WOW from "wowjs";

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProjectsList: null,

        };
    }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromMap", 2);
        sessionStorage.setItem("fromBlog", 0);
        this.props.hideEventsNavigation(false, 0);
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',

            }
        };

        fetch('https://classicalfutures.eu/api/projects', obj)
            .then(response => response.json())
            .then(data => this.setState({ProjectsList: data.data})).then(function () {
            if (document.getElementsByClassName("dp_preloader")[0].classList.contains("hidden")) {
                if (window.innerWidth > 769) {
                    const wow = new WOW.WOW();
                    wow.init();
                }
            } else {
                var elems = document.querySelectorAll(".hidden-w");

                [].forEach.call(elems, function (el) {
                    el.classList.remove("hidden-w");
                });
            }
        }).then(() => this.props.showFooter()).catch(err => console.log(err));

        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);


    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {

    }

    render() {
        let {ProjectsList} = this.state;
        if (ProjectsList) console.log(ProjectsList.reduce((accumulated, member, index) => {
            if (index % 2 === 0) {
                accumulated.push([]);
            }
            accumulated[accumulated.length - 1].push(member);
            return accumulated;
        }, []));
        return (
            <div>
                <div className="cutted-background"></div>
                <div className="projects-list">

                    <div className="introduction">
                        <div className="introduction-text"> Discover the Classical Futures events featured in your
                            country or region.
                        </div>
                    </div>
                    <div className="project-cards-holder">
                        {ProjectsList != null ?
                            ProjectsList.reduce((accumulated, member, index) => {
                                if (index % 2 === 0) {
                                    accumulated.push([]);
                                }
                                accumulated[accumulated.length - 1].push(member);
                                return accumulated;
                            }, []).map((item, index) => (
                                <div key={"project-row-" + index} className={"row no-gutters margin-" + index}>
                                    <div className="col-12" style={{marginLeft: index > 2 ? 103 + 'px' : 0}}>
                                        <div className="spacer" style={{width: (index % 3) * 33 + (index % 6 == 0 && index > 5 ? "100" : "") + "px"}}></div>
                                        {item.map((projectCard, i) => {
                                            return (<ProjectCard index={index + i}
                                                                 image={projectCard.PictureSource}
                                                                 link={"/project/" + projectCard.Details.Slug}
                                                                 logoWhite={projectCard.LogoWhite}
                                                                 logoBlack={projectCard.LogoBlack}
                                                                 name={projectCard.Details.Title}></ProjectCard>)
                                        })}
                                    </div>
                                </div>
                            )) : ''
                        }
                    </div>
                </div>
            </div>
        )
    }

}


export default Projects;