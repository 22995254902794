import React, {Component} from 'react';
import DelayLink from './Delay.jsx';

class ProjectCard extends Component {

    render() {
        return (
            <div key={"project-card-" + this.props.index} className={this.props.index < 12 ? "project-card-holder hidden-w wow fadeInRight" : "hidden-w project-card-holder"} data-wow-duration="0.8s" data-wow-delay={1 + this.props.index*0.2 + "s"}>
                <div className="mobile-project-title">
                    {this.props.name ? this.props.name : ''}
                </div>
                <div className="overlay-container">
                    <svg className="project-card" width="100%" height="300px">
                        <image preserveAspectRatio="none" width="100%" height="300px"
                               xlinkHref={this.props.image}></image>
                    </svg>

                    {this.props.link ? <DelayLink
                        to={this.props.link}
                        delay={2000}
                        onDelayStart={() => { 'started' }}
                        onDelayEnd={() => { 'ended' }} >
                        <div className="overlay">
                            <img className="overlayed-image" alt={this.props.name} src={'https://classicalfutures.eu/cdn/Media/Projects/Logo/White/' + this.props.logoWhite}/></div>
                    </DelayLink> : ''}
                </div>
                <div className="mobile-project-logo">
                    <div className="row">
                        <div className="col-6">
                            { this.props.logoBlack ? <img alt={this.props.name + "-black"} className="overlayed-image" src={'https://classicalfutures.eu/cdn//Media/Projects/Logo/Black/' + this.props.logoBlack}/> : ''}
                        </div>
                        <div className="col-6">
                            <div className="button-box">
                                {this.props.link ?  <DelayLink
                                    to={this.props.link}
                                    delay={2000}
                                    onDelayStart={() => { 'started' }}
                                    onDelayEnd={() => { 'ended' }} ><button><div className="button-skewed-clear">Know more</div></button></DelayLink> : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjectCard;