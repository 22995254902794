import React, {Component} from 'react';
import BlogPost from "./BlogPost.jsx";

var key = '';

class SearchResults extends Component {
    constructor() {
        super();
        this.state = {
            searchData: null
        }
    }
   componentDidUpdate() {
       document.getElementById("overlayer").classList.remove('active')
   }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromBlog",0);
        setTimeout(() => {
            this.props.hideLoader();
            document.getElementById("overlayer").classList.remove('active')
        }, 2800);
        key = this.props.match.params.key;

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/search/' + key, obj)
            .then(response => response.json())
            .then(data => this.setState({searchData: data})).then(() => this.props.showFooter()).catch(err => console.log(err));
    }


    render() {
        let {searchData} = this.state;
        let ids = [];
        let noResultsMessage = '';
        if (this.props.options) {
            noResultsMessage = this.props.options.no_results;
        }
        return (
            <div className="search-results page">
                <div className="row">
                    <div className="col-12">
                        <h1 className="section-title">Search Results for: <span>{key}</span></h1>
                    </div>
                </div>
                <div className="row">
                    {searchData !== null ?
                        <div className="col-12">
                            {searchData.blogs.length > 0 ? <div className="search-category">Blog:</div> : ''}
                            {searchData.blogs.length > 0 ?
                                searchData.blogs.map((item, ind) => (
                                    <BlogPost key={"result-blog-" + ind} button="1"
                                              link={"/blog-post/" + item.Details.Slug}
                                              image={item.Picture != null ? item.PictureUrl : 0}
                                              title={item.Details.Title}
                                              buttonText={"Read More"}
                                              description={item.Details.Description}></BlogPost>
                                ))
                                : ''}
                            {searchData.sponsors.length > 0 ? <div className="search-category">Sponsors:</div> : ''}
                            {searchData.projects.length > 0 ? <div className="search-category">Projects:</div> : ''}
                            {searchData.projects.length > 0 ?
                                searchData.projects.map((item, ind) => (
                                    <BlogPost key={"result-proj-" + ind} button="1"
                                              link={"/project/" + item.Details.Slug}
                                              image={item.PictureUrl}
                                              title={item.Details.Title}
                                              buttonText={"Read More"}
                                              description={item.Details.Description}></BlogPost>
                                )) : ''}
                            {searchData.events.length > 0 ? <div className="search-category">Events:</div> : ''}
                            {searchData.events.length > 0 ?
                                searchData.events.map((item, ind) => (
                                    <BlogPost key={"result-ev-" + ind} button="1"
                                              linkOut={1}
                                              link={item.Url}
                                              image={item.PictureUrl}
                                              buttonText={"Read More"}
                                              title={item.Details.Title}
                                              description={item.Details.Description}></BlogPost>
                                )) : ''}
                            {searchData.blogs.length == 0 && searchData.projects.length == 0 && searchData.events.length == 0 ?
                                <div className="no-results">{noResultsMessage}</div> : ""}
                        </div>
                        : ''}
                </div>
            </div>

        )
    }

}


export default SearchResults;