import React from 'react';
import Swiper from 'react-id-swiper';
import DelayLink from './Delay.jsx';
import ReactPlayer from "react-player";

class Carousel extends React.Component {
    constructor() {
        super()
        this.goNext = this.goNext.bind(this)
        this.goPrev = this.goPrev.bind(this)
        this.swiper = null
    }

    goNext() {
        if (this.swiper) this.swiper.slideNext()
    }

    goPrev() {
        if (this.swiper) this.swiper.slidePrev()
    }

    render() {
        var scrollbar = {};

        if (this.props.hideSwipe !== 'true') {
            scrollbar = {
                el: '.swiper-scrollbar',
                hide: false,
            };
        }
        var number = this.props.number;
        if (this.props.index) {
            startKey =  this.props.index;
        } else {
            startKey =  0;
        }
        if (window.innerWidth < 1700) {

            if (this.props.number > 1) {
                number = 3;
                if (window.innerWidth < 1250) {
                    number = 2;
                }
            }

            if (window.innerWidth < 768) {
                number = 1;
            }
        }
        var slidesPerView = number;
        var slidesPerColumn = 1;
        if (this.props.rows == '2' && window.innerWidth < 1024) {
            slidesPerView = 2;
            slidesPerColumn = 2;
        }

        var startKey = 0;
        if (this.props.index) {
            startKey = this.props.index;
        }
        var autoplay = false;
        if (this.props.auto != 0) {
            autoplay = { delay: 4000,
                disableOnInteraction: false};
        }
        const params = {
            scrollbar: scrollbar,
            /*rebuildOnUpdate : true,*/
            effect: this.props.effect,
            spaceBetween: 0,
            slidesPerColumn: slidesPerColumn,
            slidesPerView: slidesPerView,
            freeMode: true,
            freeModeSticky: true,
            loop: true,
            centerMode: false,
            activeSlideKey: (startKey + 1).toString(),
            shouldSwiperUpdate :true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            autoplay: autoplay,
            breakpoints: {
                768: {
                    autoplay: autoplay,
                    freeMode: true,
                    centerMode: true,
                    freeModeSticky: true

                }
            }
        };


        return (
            <div className="row">
                <div className="col-12 ">
                    <div>
                        <Swiper {...params} ref={node => this.swiper = node ? node.swiper : null}>
                            {this.props.images.map((image, index) => (
                                image[0].link ?
                                    <div key={index - 1}>
                                        <DelayLink
                                            to={image[0].link}
                                            delay={2000}
                                            onDelayStart={() => {
                                                'started'
                                            }}
                                            onDelayEnd={() => {
                                                'ended'
                                            }}>
                                            <div className="image-carousel"
                                                 style={{backgroundImage: `url( ${image[0].url})`}}></div>
                                            <span
                                                className="copyright"> {image[0].copyright ? 'Photographer:' : ''} {image.copyright}</span>
                                        </DelayLink>
                                    </div>
                                    : <div key={index}>
                                        <div className="swiper-overlay"></div>
                                        {image[0].media != 0 ? <div className="image-carousel"><div className="video-wrapper">
                                            <ReactPlayer width="100%" url={ image[0].media}/>
                                        </div></div> : <div className="image-carousel"
                                                                 style={{backgroundImage: `url( ${image[0].url})`}}></div>}
                                        <span
                                            className="copyright"> {image[0].copyright ? 'Photographer:' : ''} {image[0].copyright}</span>
                                    </div>



                            ))}
                        </Swiper>
                        <button className="swiper-button-next" onClick={this.goNext}></button>
                        <button className="swiper-button-prev" onClick={this.goPrev}></button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Carousel;