import React, {Component} from 'react';
import GalleryCard from "./GalleryCard.jsx"
import Carousel from './Carousel.jsx';
import {Button, Modal} from "react-bootstrap";
import Dropdown from './Dropdown.jsx';
import WOW from "wowjs";

var ImageRight = require('../Assets/cfe_logo_right.svg');
var ImageLeft = require('../Assets/cfe_logo_left.svg');

var image1 = require('../Assets/placeh1.jpg');
var image2 = require('../Assets/placeh2.jpg');
var image3 = require('../Assets/placeh3.jpg');
var image4 = require('../Assets/placeh4.jpg');
var image5 = require('../Assets/placeh5.jpg');
var image6 = require('../Assets/placeh6.jpg');

var audioUrl = "http://localhost:3000//static/media/test.2ca80224.mp3";

var closeIcon = require('../Assets/exit-white.svg');
var array = new Array({url: image2, media: "https://player.vimeo.com/video/40999594"});
var array2 = new Array({url: image1});
var array3 = new Array({url: image3});
var array4 = new Array({url: image4});
var array5 = new Array({url: image5});
var array6 = new Array({url: image6});
var imagesArray = [array, array, array3, array4, array5, array6, array2, array, array3, array4, array5, array6];

class Gallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ProjectsList: null,
            slideShow: false,
            activeSlideKey: 0,
            dropdownItemsArray: [[2019, 2019]],
            scrollTop: null,
            builderSections: null,
            carouselItems: null
        };
    }

    toggleSlideShow(index) {
        if (this.state.slideShow) {
            this.setState({slideShow: false});
            document.getElementsByClassName("page-content")[0].classList.remove("no-scroll");
        } else {
            this.setState({activeSlideKey: index});
            this.setState({slideShow: true});
            window.scrollTo(0, this.state.scrollTop * (-1));
        }
    }

    componentDidMount() {
        this.props.hideFooter();
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/page/gallery', obj)
            .then(response => response.json())
            .then(data => {
                if (data.data.Active) {

                    this.setState({builderSections: data.data});
                    this.setState({
                        carouselItems: data.data.BuilderDetails.Layouts[0].Widgets[0].AdditionalData.Gallery.map(item => new Array({
                            'url': item.pictureUrl,
                            'media': item.type == "Picture" ? 0 : item.sourceUrl
                        }))
                    });
                } else {
                    window.location.href= "404";
                }

            }).then(() =>
            this.updateDimensions()).then(function () {
            if (document.getElementsByClassName("dp_preloader")[0].classList.contains("hidden")) {
                if (window.innerWidth > 769 && window.navigator.userAgent.indexOf('safari') <= -1) {
                    const wow = new WOW.WOW();
                    wow.init()
                }
            }
                var elems = document.querySelectorAll(".hidden-w");

                [].forEach.call(elems, function(el) {
                    el.classList.remove("hidden-w");
                });

        }).then(() => this.props.showFooter()).catch(err => console.log(err));


        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.scrollGallery.bind(this));
        this.updateDimensions();
        sessionStorage.setItem("fromBlog", 0);
        this.props.getWhiteNav();
        this.props.hideEventsNavigation(true, 0);

        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);


    }

    scrollGallery() {
        this.setState({scrollTop: document.body.getBoundingClientRect().top});
    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
        if (window.innerWidth < 1024) {
            this.props.getBlackNav();
        }
    }

    updateDimensions() {
        if (window.innerWidth > 769) {
            var el = document.getElementById("mask-inner-year");
            if (el) {
                var left = window.innerWidth - 330;
                el.setAttribute("x", left + 'px');
            }
        }
    }

    getMediaForYear() {
    }

    render() {
        let {ProjectsList, slideShow, activeSlideKey, dropdownItemsArray, builderSections, carouselItems} = this.state;
        let data = '<div>';
        return (
            <div>
                <div className="row gallery-switcher-year">
                    <div className="row">
                        <div className="col-12 dropdown-wrapper">
                            {dropdownItemsArray !== null ? <Dropdown type="Year" items={dropdownItemsArray}
                                                                     clickFunction={this.getMediaForYear.bind(this)}/> : ''}
                        </div>
                    </div>
                    <div className="col-12 dropdown-wrapper-desktop">
                        <div className="svg-blog">
                            <svg className="masked-element-year" width="100%" height="350px">
                                <svg width="300%" height="350px">
                                    <rect className="rectangle-background" x="0" y="0" width="100%" height="350px"
                                          fill="white"/>
                                </svg>
                            </svg>
                            <div className="arrow-down"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-mask" width="100%">
                                <defs>
                                    <mask id="mask-year">
                                        <svg width="100%" height="350px" x="calc(100% - 330px)" id="mask-inner-year">
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-240px"
                                                   xlinkHref={ImageRight}>
                                            </image>
                                            <circle cx="175" cy="175" r="175" fill="white"/>
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-120px"
                                                   xlinkHref={ImageLeft}>
                                            </image>
                                        </svg>
                                    </mask>
                                </defs>
                            </svg>
                        </div>
                        {dropdownItemsArray !== null ? <Dropdown type="Year" items={dropdownItemsArray}
                                                                 clickFunction={this.getMediaForYear.bind(this)}/> : ''}
                    </div>
                </div>
                <div className="projects-list gallery-list">
                    <div className="project-cards-holder gallery">
                        {
                            builderSections !== null ?
                                builderSections.BuilderDetails.Layouts.map((item) => (
                                    item.Widgets != null ?

                                        item.Widgets.map((widget) => {
                                            switch (widget.Type) {

                                                case 4:
                                                    console.log(widget);
                                                    data = widget.AdditionalData.Gallery.reduce((accumulated, member, index) => {
                                                        if (index % 2 === 0) {
                                                            accumulated.push([]);
                                                        }
                                                        accumulated[accumulated.length - 1].push(member);
                                                        return accumulated;
                                                    }, []).map((item, index) => (
                                                        <div key={"project-row-" + index}
                                                             className={"row no-gutters margin-row"}>
                                                            <div className="col-12"
                                                                 style={{marginLeft: index > 2 ? 101 + 'px' : 0}}>
                                                                <div className="spacer"
                                                                     style={{width: (index % 3) * 33  + (index % 6 == 0 && index > 5 ? "100" : "") + "px"}}></div>
                                                                {item.map((element, i) => {
                                                                    return (<GalleryCard index={i + index * 2}
                                                                                         type={element.type}
                                                                                         title={element.title}
                                                                                         image={element.pictureUrl}
                                                                                         onClick={() => this.toggleSlideShow(i + index * 2)}></GalleryCard>)
                                                                })}
                                                            </div>
                                                        </div>

                                                    ));
                                                    return data;
                                                default:
                                                    return '';
                                            }
                                        }) : ''

                                )) : ''}


                    </div>

                    <div className={slideShow ? "gallery-carousel " : "gallery-carousel hidden"}>
                        <Button className="close-button" onClick={this.toggleSlideShow.bind(this)}><img
                            className="close-icon" alt="close" src={closeIcon}></img></Button>
                        {
                            carouselItems !== null ? <Carousel index={activeSlideKey} number="1" auto="0"
                                                               effect="fade"
                                                               hideSwipe="true"
                                                               images={carouselItems}></Carousel> : ''}
                        <div className="button-background-left"></div>
                        <div className="button-background-right"></div>
                    </div>
                </div>
            </div>
        )
    }

}


export default Gallery;