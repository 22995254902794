import React, {Component} from 'react';
import DelayLink from './Delay.jsx';
class BlogPost extends Component {

    render() {
        return (
            <div className="article-section-holder">
                    <div className={this.props.image  ? "article-section" : "article-section single-article" }>
                        <div className="row">
                            <div key='row-2' className={this.props.image  ? "second col-sm-6 col-12 text-wrapper" : "second col-sm-8 col-12 text-wrapper"}>
                                {this.props.title ? <h2 className="article-title-desktop">{this.props.title}</h2> : ''}
                                {this.props.date ? <span className="single-project-list-date">{this.props.date}</span> : ''}

                                <div className={this.props.title  ? "small-article-description" : "large-article-description"} dangerouslySetInnerHTML={{ __html: this.props.description}}></div>
                                {this.props.link  ?
                                <div  className="button-box">
                                    {this.props.linkOut ? <a href={this.props.link} target="_blank"><button><div className="button-skewed-clear">{this.props.buttonText}</div></button></a> :
                                    <DelayLink
                                        to={this.props.link}
                                        delay={2000}
                                        onDelayStart={() => { 'started' }}
                                        onDelayEnd={() => { 'ended' }} ><button><div className="button-skewed-clear">{this.props.buttonText}</div></button></DelayLink>
                                    }
                                   </div> : ""}
                                <div className="small-skewed-box"></div>
                            </div>
                            <div key='row-3' className="first col-sm-6 col-12 image-wrapper">
                                {this.props.title ? <h2 className="article-title-mobile">{this.props.title}</h2> : ''}
                                {this.props.image  ? <div className="article-image" style={{marginLeft: this.props.margin  ? "-" + this.props.margin + "%" : '-40%' ,backgroundImage: `url(${this.props.image})`, backgroundPosition: `${this.props.backgroundPosition}`}}>{this.props.copyright ?  <span className="copyright-image" style={{left: this.props.margin == '65'  ? "35%" : "19%"}}>{this.props.copyright}</span> : ''}</div> : ''}
                            </div>
                        </div>
                    </div>
            </div>

        )
    }

}


export default BlogPost;