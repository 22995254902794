import React, {Component} from "react";
import BlogPost from "./BlogPost.jsx";
import DelayLink from "./Delay.jsx";
import TextOnlyComponent from "./TextOnlyComponent";
import ReactPlayer from "react-player";
import {ReactComponent as LogoArrow} from "../Assets/arrow_left_white.svg";
import Person from "./Person";
import GalleryCard from "./GalleryCard";
import Carousel from "./Carousel";
import Swiper from "react-id-swiper";

class SingleBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            builderSections: null
        };

    }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0)
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',


            }
        };

        fetch('https://classicalfutures.eu/api/single-blog/' + this.props.match.params.name, obj)
            .then(response => response.json())
            .then(data => this.setState({builderSections: data.data})).then(() => this.props.showFooter()).catch(err => console.log(err));


    }


    render() {
        let {builderSections} = this.state;
        let date = '';
        let options = this.props.options;
        if (builderSections != null) {
            date = new Date(builderSections.Date.date + " UTC").getUTCDate() + "/ " + (new Date(builderSections.Date.date).getUTCMonth() + 1) + "/" + new Date(builderSections.Date.date).getUTCFullYear();
        }
        var scrollbar = {};


        scrollbar = {
            el: '.swiper-scrollbar',
            hide: false,
        };

        const params = {
            scrollbar: scrollbar,
            /*rebuildOnUpdate : true,*/
            effect: this.props.effect,
            spaceBetween: 0,
            slidesPerColumn: 1,
            slidesPerView: 1,
            freeMode: true,
            freeModeSticky: true,
            loop: true,
            centerMode: false,
            shouldSwiperUpdate: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            autoplay: false,
            breakpoints: {
                768: {
                    autoplay: false,
                    freeMode: true,
                    centerMode: true,
                    freeModeSticky: true

                }
            }
        };

        return (
            <div className="page">
                <div className="cutted-background"></div>
                <div className="row">
                    {sessionStorage.getItem("fromBlog") == "1" ?
                        <DelayLink to="/blog" hideEventsNavigation={this.props.hideEventsNavigation}
                                   delay={2000}
                                   onDelayStart={() => {
                                       'started'
                                   }}
                                   onDelayEnd={() => {
                                       'ended'
                                   }}>
                            <div className="blog-back-button"><LogoArrow></LogoArrow></div>
                        </DelayLink>

                        : <div className="blog-back-button">
                            <button onClick={() => window.history.go(-1)}><LogoArrow></LogoArrow>
                            </button>
                        </div>}

                </div>
                <div className="single-blog">
                    <div className="row">
                        <div className="col-xl-7 col-12">
                            <div className="blog-title">
                                <h1>{builderSections !== null ? builderSections.Details.Title : ''}</h1>
                            </div>
                        </div>
                        <div className="col-xl-5 col-12 grid">{builderSections !== null ?
                            <span className="article-date">{/*date*/}</span> : ''}</div>
                        {builderSections !== null ?
                            builderSections.BuilderDetails.Layouts.map((item) => (
                                item.Widgets != null ?
                                    item.Widgets.map((widget) => {
                                        switch (widget.Type) {
                                            case 19:
                                                return <div
                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                    <img src={widget.Options.pictureUrl}/></div>;

                                            case 23:
                                                return <TextOnlyComponent description={widget.Options.description}
                                                                          background={0}
                                                                          column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}/>;
                                            case 21:
                                                return <BlogPost image={widget.Options.pictureUrl}
                                                                 description={widget.Options.description}></BlogPost>
                                            case 16:
                                                return <div className="video-wrapper">
                                                    <ReactPlayer width="100%" url={widget.Options.content}/>
                                                </div>;
                                            case 4:
                                                console.log(widget.Options.media);
                                                return <div className="col-12 ">
                                                        <div>
                                                            <Swiper {...params}
                                                                    ref={node => this.swiper = node ? node.swiper : null}>
                                                                {widget.Options.media.map((image, index) => (
                                                                    <div key={index}>
                                                                            <div className="swiper-overlay"></div>
                                                                          <div className="image-carousel"
                                                                                              style={{backgroundImage: `url( ${image.pictureUrl})`}}></div>
                                                                            <span
                                                                                className="copyright"> {image.copyright ? 'Photographer:' : ''} {image.copyright}</span>
                                                                        </div>
                                                                ))}
                                                            </Swiper>
                                                        </div>
                                                </div>;
                                            case 10:
                                                return <Person name={widget.Options.name}
                                                               description={widget.Options.description}
                                                               position={widget.Options.position}
                                                               image={widget.Options.pictureUrl ? this.props.getFullImage(widget.Options.pictureUrl) : ""}></Person>;
                                            default :
                                                return '';
                                        }
                                    }) : ''

                            )) : ''}
                    </div>

                    <div className="row">
                        <div className="col-xl-4 col-0"></div>
                        <div className="col-xl-7">
                            <div className="box-button single-blog-button">
                                <DelayLink hideEventsNavigation={this.props.hideEventsNavigation}
                                           to="/blog"
                                           delay={2000}
                                           onDelayStart={() => {
                                               'started'
                                           }}
                                           onDelayEnd={() => {
                                               'ended'
                                           }}>
                                    <div className="button-skewed-clear">
                                        <button>{options != null ? options.blog_btn : ''}</button>
                                    </div>
                                </DelayLink>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        )
    }

}


export default SingleBlog;