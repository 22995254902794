import React, {Component} from 'react';

var VideoIcon = require('../Assets/video-button.png');
var AudioIcon = require('../Assets/note.png');

class GalleryCard extends Component {

render()
{
    return (
        <div className={this.props.index < 8 ? "project-card-holder wow hidden-w fadeInRight" : "hidden-w project-card-holder"} data-wow-duration="0.8s" data-wow-delay={this.props.index < 8 ? 1 + this.props.index*0.2 + "s" : 0.3 + (this.props.index%2)*0.3 +"s"}>
            <div className="overlay-container">
                <div className="project-card">
                    <div style={{backgroundImage: `url( ${this.props.image})`}}/>
                </div>

                <div className="overlay" onClick={this.props.onClick}>
                    {this.props.title ? <div className="gallery-card-description">{this.props.title}</div> : ""}
                    {this.props.type == 'Video' ? <img className="video-play-icon media-icon" src={VideoIcon}/> : ""}
                    {this.props.type == 'Audio' ? <img className="audio-play-icon media-icon" src={AudioIcon}/> : ""}
                </div>
            </div>


        </div>
    )
}
}

export default GalleryCard;