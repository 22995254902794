import React, {Component} from "react";
import DelayLink from "./Delay.jsx";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from "google-maps-react";
import {withRouter} from "react-router-dom";

let styles = [
    {
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#333333"
            },
            {
                "saturation": -45
            },
            {
                "lightness": 80
            },
            {
                "weight": 2
            }
        ]
    },
    {
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#f5f5f5"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "administrative.country",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "100"
            },
            {
                "weight": "1"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "100"
            },
            {
                "gamma": "10.00"
            },
            {
                "lightness": "-100"
            },
            {
                "weight": "1"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "road",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#757575"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dadada"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#616161"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#9e9e9e"
            }
        ]
    },
    {
        "featureType": "transit",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e5e5e5"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#868686"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": 90
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#868686"
            }
        ]
    }
];

export class ProjectsMap extends Component {
    constructor() {
        super();
        this.state = {
            //showingInfoWindow: true,
            //activeMarker: {},
            //selectedPlace: {},
            Markers: null,
            center: {lat: 53.36044, lng: -6.26408}
            //activeMarkerId: null
        };
    }

    componentDidMount() {
        sessionStorage.setItem("fromBlog", 0);
        sessionStorage.setItem("fromMap", 1);
        this.props.hideEventsNavigation(false, 1);
        window.scrollTo(0, 0);
        this.props.hideFooter();
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',

            }
        };

        fetch('https://classicalfutures.eu/api/projects', obj)
            .then(response => response.json())
            .then(data => this.setState({Markers: data.data})).catch(err => console.log(err));

        setTimeout(() => {
            this.props.hideLoader();
            window.dispatchEvent(new Event('resize'));
            window.addEventListener("mousewheel", this.hideInfoWindow.bind(this));
            window.addEventListener("DOMMouseScroll", this.hideInfoWindow.bind(this));
        }, 3500);

    }


    componentWillReceiveProps(nextProps) {

    }


    onMarkerClick = (props, marker, e) => {
        console.log(e);
        var timeout = 800;

        var iw = document.getElementById("custom-info-window");
        var iw2 = document.getElementById("custom-info-window-2");
        var image = '';
        var link = '';
        var zMarker = document.getElementsByClassName("active-marker");
        if (document.getElementsByClassName("hidden-marker").length > 0) {
            document.getElementsByClassName("hidden-marker")[0].classList.remove("hidden-marker");
        }
        var zoomedMarker = '';
        document.getElementsByClassName("project-map-link")[0].classList.remove("active-btn");
        document.getElementsByClassName("project-map-link")[1].classList.remove("active-btn");
        if (zMarker.length > 0) {
            setTimeout(function () {
                zMarker[0].classList.remove("active-marker");
            }, 350);
        }
        var markerX = '';
        var markerY = '';
        var infoX = '';
        var infoY = '';
        if (window.innerWidth < 1024) {
            var pos = props.position;
            var lng = pos.lng ;
            this.hideInfoWindow();
            props.map.panTo({lat: parseFloat(pos.lat), lng: parseFloat(lng) + 4});

        } else {
            timeout = 0;
        }
        setTimeout(function () {
            if ("ontouchstart" in document.documentElement) {
                var ua = navigator.userAgent.toLowerCase();
                var isAndroid = ua.indexOf("android") > -1;
                if (!isAndroid) {
                    markerX = e.vb.target.x + 26 + "px";
                    markerY = e.vb.target.y - 20 + "px";
                    infoX = e.vb.target.x + 55 + "px";
                    infoY = e.vb.target.y - 26 + "px";
                } else {
                    markerX = window.innerWidth / 2 + 20  - 42+ "px";
                    markerY = window.innerHeight / 2 - 43 + "px";
                    infoX = window.innerWidth / 2 + 49 - 42 + "px";
                    infoY = window.innerHeight / 2 - 50 + "px";

                }
            } else {
                /*if (window.navigator.userAgent.indexOf("Edge") > -1) {

                    markerX = e.ya.srcElement.x + 17 + "px";
                    markerY = e.ya.srcElement.y - 28 + "px";
                    infoX = e.ya.srcElement.x + 46 + "px";
                    infoY = e.ya.srcElement.y - 33 + "px";
                } else {*/
                    console.log(e);
                    markerX = e.domEvent.clientX - e.domEvent.layerX + 17 + "px";
                    markerY = e.domEvent.clientY - e.domEvent.layerY - 28 + "px";
                    infoX = e.domEvent.clientX - e.domEvent.layerX + 46 + "px";
                    infoY = e.domEvent.clientY - e.domEvent.layerY - 34 + "px";
                /*}*/
            }


            if (iw.classList.contains("clicked")) {
                zoomedMarker = document.getElementById("zoomed-marker-2");
                zoomedMarker.style.left = markerX;
                zoomedMarker.style.top = markerY;
                setTimeout(function () {
                    zoomedMarker.classList.add("active-marker");
                }, 350);
                image = iw2.querySelector('.label-logo');
                link = iw2.querySelector('.project-map-link');
                link.setAttribute("slug", "/project/" + marker.slug);
                link.classList.add("active-btn");
                image.src = marker.logo;
                iw2.style.left = infoX;
                iw2.style.top = infoY;
                setTimeout(function () {
                    iw2.classList.add("active");
                }, 600);

                iw.classList.remove("active");
                iw.classList.remove("clicked");
            } else {
                zoomedMarker = document.getElementById("zoomed-marker");
                zoomedMarker.style.left = markerX;
                zoomedMarker.style.top = markerY;
                setTimeout(function () {
                    zoomedMarker.classList.add("active-marker");
                }, 350);
                image = iw.querySelector('.label-logo');
                image.src = marker.logo;
                link = iw.querySelector('.project-map-link');
                link.setAttribute("slug", "/project/" + marker.slug);
                link.classList.add("active-btn");
                iw.classList.add("clicked");
                iw2.classList.remove("active");

                iw.style.left = infoX;
                iw.style.top = infoY;
                setTimeout(function () {
                    iw.classList.add("active");
                }, 600);
            }
        }, timeout)


    };


    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
        this.props.showFooter();
    }

    hideInfoWindow() {
        var iw = document.getElementById("custom-info-window");
        var iw2 = document.getElementById("custom-info-window-2");
        if (iw) {
            iw.classList.add("hidden");
            iw2.classList.add("hidden");
            var zoomedMarker = document.getElementsByClassName("active-marker");
            if (zoomedMarker.length > 0) {
                zoomedMarker[0].classList.add("hidden-marker");
            }
            this.hideMarkers();
            setTimeout(function () {
                iw.classList.remove("hidden");
                iw2.classList.remove("hidden");
            }, 200);

            setTimeout(function () {
                var p = document.getElementsByClassName("hidden-marker");
                if (p.length > 0) {
                    p[0].classList.remove("hidden-marker");
                }
            }, 400);
        }
    }

    onMapClicked = () => {
        this.hideMarkers();

    };

    hideMarkers() {
        var iw = document.getElementById("custom-info-window");
        var iw2 = document.getElementById("custom-info-window-2");
        iw.classList.remove("active");
        iw.classList.remove("clicked");
        iw2.classList.remove("active");

        setTimeout(function () {
            var zoomedMarker = document.getElementsByClassName("active-marker");
            if (zoomedMarker.length > 0) {
                zoomedMarker[0].classList.remove("active-marker");
            }
        }, 350);
    }

    onSingleProjectRedirect = () => {
        this.props.showPageTransition();
        var btn = document.getElementsByClassName("active-btn")[0];
        var This = this;
        setTimeout(function () {
            This.props.history.push(btn.getAttribute("slug"));
        }, 2000)


    };
    onMouseOver = () => {
        console.log(this);
    }

    render() {
        const {showingInfoWindow, activeMarker, activeMarkerId} = this.state;
        let {Markers, center} = this.state;

        return (
            <div className="map-holder">
                <div className="row">
                    <div className="col-12">

                        <div className="custom-iw" id="custom-info-window">
                            <button className="project-map-link" onClick={this.onSingleProjectRedirect}>
                                <div className="label-logo-holder">
                                    <img className="label-logo" src=""/>
                                </div>
                                <div className="map-arrow-holder"></div>
                                <div className="map-arrow"></div>
                            </button>
                        </div>
                        <div className="zoomed-marker" id="zoomed-marker">
                            <img src={process.env.PUBLIC_URL + 'marker_black.png'}/>
                        </div>
                        <div className="zoomed-marker" id="zoomed-marker-2">
                            <img src={process.env.PUBLIC_URL + 'marker_black.png'}/>
                        </div>
                        <div className="custom-iw" id="custom-info-window-2">
                            <button className="project-map-link" onClick={this.onSingleProjectRedirect}>
                                <div className="label-logo-holder">
                                    <img className="label-logo" src="" id="custom-info-window-logo"/>
                                </div>
                                <div className="map-arrow-holder"></div>
                                <div className="map-arrow"></div>
                            </button>
                        </div>
                        <Map initialCenter={center} clickableIcons={true} panControl={true}
                             onDragstart={this.hideInfoWindow.bind(this)}
                             scrollwheel={false}
                             disableDoubleClickZoom={true}
                             onClick={this.onMapClicked.bind(this)}
                             defaultOptions={{
                                 styles: styles
                             }} google={this.props.google} zoom={4.1} style={{height: '100vh'}} styles={styles}>

                            {Markers != null ?
                                Markers.map((item, index) => (
                                    <Marker id={"marker" + index}
                                            icon={{
                                                url: process.env.PUBLIC_URL + 'marker_black.png',
                                                scaledSize: new this.props.google.maps.Size(activeMarkerId === 1 ? 100 : 18, activeMarkerId === 1 ? 100 : 42)
                                            }}
                                            slug={item.Details.Slug}
                                            onClick={this.onMarkerClick.bind(this)}
                                            name={item.Details.Title}
                                            onMouseover={this.onMouseOver.bind(this)}
                                            logo={"https://classicalfutures.eu/cdn/Media/Projects/Logo/White/" + item.LogoWhite}
                                            position={{lat: item.Latitude, lng: item.Longitude}}
                                    />
                                )) : ''
                            }


                            <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
                                <div></div>
                            </InfoWindow>
                        </Map>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(GoogleApiWrapper({

    apiKey: (
        'AIzaSyAeVV0LpO_ZS5QR1Jb95LVyxAg49tqw748'
    )
})(ProjectsMap))
