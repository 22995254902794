import React, {Component} from 'react';
import './App.scss';
import {Router, Route, Switch} from "react-router-dom";
import Home from './Components/Home.jsx';
import SingleProjectList from './Components/SingleProjectList.jsx';
import SingleBlog from './Components/SingleBlog.jsx';
import About from './Components/About.jsx';
import Blog from './Components/Blog.jsx';
import Projects from './Components/Projects.jsx';
import ProjectsMap from './Components/Map.jsx';
import Sidebar from './Components/Sidebar.jsx';
import Contact from './Components/Contact.jsx';
import Resources from './Components/Resources.jsx';
import SearchResults from './Components/SearchResults.jsx';
import Page404 from './Components/Page404.jsx';
import ResourcesDownload from './Components/ResourcesDownload.jsx';
import Footer from './Components/Footer.jsx';
import DelayLink from './Components/Delay.jsx';
import Gallery from './Components/Gallery.jsx';

import ReactGA from 'react-ga';
import createBrowserHistory from 'history/createBrowserHistory';

const trackingId = 'UA-122568579-1';
const history = createBrowserHistory();

const pageStyles = {
    "opacity": 0,        //Has to be inline to avoid HTML loading before load
    "height": 0
};
ReactGA.initialize(trackingId, true);
history.listen((location, action) => {
    ReactGA.set({page: location.pathname}); // Update the user's current page
    ReactGA.pageview(location.pathname + location.search);
    console.log(location.pathname)
});

class App extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: true,
            isActive: false,
            visibleFooter: true,
            options: this.getSiteOptions(),
            scrollableMobile: false,
            isEvents: false,
            isMap: false
        }
    }

    fireTracking() {
        history.listen((location, action) => {
            ReactGA.set({page: location.pathname}); // Update the user's current page
            ReactGA.pageview(location.pathname + location.search);
            console.log(location.pathname)
        });

    }

    componentDidMount() {


        if (window.navigator.userAgent.indexOf('Trident/') > -1) {
            alert("This website does not support Internet Explorer, we strongly encourage you to look on a different browser (Chrome, Mozilla, Safari or Opera)");
        }
    }

    getSiteOptions() {
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/site-options', obj)
            .then(response => response.json())
            .then(data => this.setState({options: data.data})).catch(err => console.log(err));
    }

    getBlackNav() {
        if (document.getElementsByClassName("black-nav-icon").length > 0) {
            document.getElementsByClassName("black-nav-icon")[0].classList.add("active");
        }
        if (document.getElementsByClassName("white-nav-icon").length > 0) {
            document.getElementsByClassName("white-nav-icon")[0].classList.remove("active");
        }
    }

    getWhiteNav() {
        if (window.innerWidth < 1024) {
            document.getElementsByClassName("black-nav-icon")[0].classList.remove("active");
            document.getElementsByClassName("white-nav-icon")[0].classList.add("active");
        }
    }

    hidePageTransition() {
        if (document.getElementById("overlayer")) {
            document.getElementById("overlayer").classList.remove('active');
            document.getElementsByClassName("page-content")[0].classList.add("active");
            document.getElementsByTagName("body")[0].classList.add('active');
        }
        var el = '';

        if (window.innerWidth > 769) {
            el = document.getElementById("active-sidebar-link");
        } else {
            el = document.getElementById("active-sidebar-link-mobile");
        }

        if (el !== null) {

            setTimeout(function () {
                if (document.getElementsByClassName("sidebar").length > 0) {
                    var sidebar = document.getElementsByClassName("sidebar")[0];
                    if (sidebar.querySelector('.active-page') != null) {
                        var active = sidebar.querySelector('.active-page').innerHTML;
                        if (document.querySelector('.active-page').getAttribute("href") !== '/' && sidebar.querySelector('.active-page').getAttribute("data-href") !== 'resources' && document.querySelector('.active-page').getAttribute("data-href") !== 'policy') {
                            el.innerHTML = active;
                        }
                    }
                }
            }, 350);
        }

    }

    showPageTransition() {
        if (document.getElementById("overlayer") != null) {
            document.getElementById("overlayer").classList.add('active');
        }

    }

    changeLoaderState() {
        this.hidePageTransition();

        setTimeout(
            this.setState({isLoading: false}), 300);

    }

    hideEventsNavigation(yes, map) {
        if (yes) {
            this.setState({isEvents: false});
            this.setState({isMap: false});
        } else {
            this.setState({isEvents: true});
            document.getElementsByClassName("map-link-text")[0].style.opacity = 0;
            document.getElementsByClassName("toggle-icon")[0].style.opacity = 0;
            if (map === 1) {
                this.setState({isMap: true});
                setTimeout(function () {
                    document.getElementsByClassName("map-link-text")[0].innerHTML = "List";
                    document.getElementsByClassName("toggle-icon")[0].classList.remove("map-icon");
                    document.getElementsByClassName("toggle-icon")[0].classList.add("list-icon");
                }, 350);
            } else {
                this.setState({isMap: false});
                setTimeout(function () {
                    document.getElementsByClassName("map-link-text")[0].innerHTML = "Map";
                    document.getElementsByClassName("toggle-icon")[0].classList.add("map-icon");
                    document.getElementsByClassName("toggle-icon")[0].classList.remove("list-icon");
                }, 350);
            }
            setTimeout(function () {
                document.getElementsByClassName("map-link-text")[0].style.opacity = 1;
                document.getElementsByClassName("toggle-icon")[0].style.opacity = 1;
            }, 350);
        }
    }

    hideFooter() {
        this.setState({visibleFooter: false});
    }

    showFooter() {
        this.setState({visibleFooter: true});
    }

    getFullImageUrl(url) {
        var url = url.replace('Medium/', '');
        return url;
    }

    getColumnNumber(layoutType, order) {
        var col = '';
        switch (layoutType) {
            case 1:
                return '12';
            case 2:
                return '6';
            case 3:
                col = '8';
                if (order !== 0) {
                    col = '4'
                }
                return col;
            case 4:
                col = '4';
                if (order !== 0) {
                    col = '8'
                }
                return col;
            case 5:
                col = '9';
                if (order !== 0) {
                    col = '3'
                }
                return col;
            case 6:
                col = '3';
                if (order !== 0) {
                    col = '9'
                }
                return col;
            case 7:
                return '4';
            case 8:
                return '3';
            case 10:
                col = '2';
                if (order !== 0) {
                    col = '10'
                }
                return col;
            case 11:
                col = '10';
                if (order !== 0) {
                    col = '2'
                }
                return col;
            default :
                return '12';
        }
    }

    render() {
        let {isLoading, visibleFooter, isActive, scrollableMobile, isEvents, isMap} = this.state;
        let homeStyle = {
            opacity: isLoading ? 0 : 1
        }
        return (
            <div>

                <div className={isLoading ? 'dp_preloader' : 'dp_preloader hidden'} id="preloader">
                    <div className="dp_loader_icon">&nbsp;</div>
                    <div></div>
                </div>
                <Router history={history} onUpdate={this.fireTracking}>

                    <div>
                        {isMap ?
                            <DelayLink
                                to={"/projects-list"}
                                delay={2000}
                                onDelayStart={() => {
                                    'started'
                                }}
                                onDelayEnd={() => {
                                    'ended'
                                }}>
                                <div className={isEvents ? "map-link text-center" : "map-link text-center hidden"}>
                                    <div className="list-icon toggle-icon"></div>
                                    <p className="map-link-text"></p>
                                </div>
                            </DelayLink> : <DelayLink
                                to={"/projects"}
                                delay={2000}
                                onDelayStart={() => {
                                    'started'
                                }}
                                onDelayEnd={() => {
                                    'ended'
                                }}>
                                <div className={isEvents ? "map-link text-center" : "map-link text-center hidden"}>
                                    <div className="list-icon toggle-icon"></div>
                                    <p className="map-link-text"></p>
                                </div>
                            </DelayLink>}

                        <div className={isActive ? 'page-content active' : "page-content"} style={pageStyles}>
                            <div id="overlayer" className="overlayer active"></div>
                            <Sidebar hidePageTransition={this.hidePageTransition.bind(this)}
                                     hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                     options={this.state.options}></Sidebar>

                            <Switch history={history}>
                                <Route key="home" exact path="/" render={(props) => <Home style={homeStyle} {...props}
                                                                                          getFullImage={this.getFullImageUrl.bind(this)}
                                                                                          getColumns={this.getColumnNumber.bind(this)}
                                                                                          hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                          showPageTransition={this.showPageTransition.bind(this)}
                                                                                          hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="about" path="/about" render={(props) => <About  {...props}
                                                                                            hideFooter={this.hideFooter.bind(this)}
                                                                                            showFooter={this.showFooter.bind(this)}
                                                                                            getFullImage={this.getFullImageUrl.bind(this)}
                                                                                            getColumns={this.getColumnNumber.bind(this)}
                                                                                            showPageTransition={this.showPageTransition.bind(this)}
                                                                                            hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                            hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="list" exact path="/projects-list" render={(props) => <Projects  {...props}
                                                                                                            hideFooter={this.hideFooter.bind(this)}
                                                                                                            showFooter={this.showFooter.bind(this)}
                                                                                                            hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                                                            hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                            hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="gallery" exact path="/gallery" render={(props) => <Gallery  {...props}
                                                                                                        hideFooter={this.hideFooter.bind(this)}
                                                                                                        showFooter={this.showFooter.bind(this)}
                                                                                                        hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                                                        hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                        hideLoader={this.changeLoaderState.bind(this)}
                                                                                                        getWhiteNav={this.getWhiteNav.bind(this)}
                                                                                                        getBlackNav={this.getBlackNav.bind(this)}/>}/>
                                getFullImage={this.getFullImageUrl.bind(this)} getColumns={this.getColumnNumber.bind(this)} />
                                <Route key="blog" path="/blog" render={(props) => <Blog {...props}
                                                                                        options={this.state.options}
                                                                                        hideFooter={this.hideFooter.bind(this)}
                                                                                        showFooter={this.showFooter.bind(this)}
                                                                                        hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                                        getColumns={this.getColumnNumber.bind(this)}
                                                                                        hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                        hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="contact" path="/contact" render={(props) => <Contact {...props}
                                                                                                 hideFooter={this.hideFooter.bind(this)}
                                                                                                 showFooter={this.showFooter.bind(this)}
                                                                                                 getFullImage={this.getFullImageUrl.bind(this)}
                                                                                                 getColumns={this.getColumnNumber.bind(this)}
                                                                                                 hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                 hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="blog-s" path="/blog-post/:name" render={(props) => <SingleBlog {...props}
                                                                                                           options={this.state.options}
                                                                                                           hideFooter={this.hideFooter.bind(this)}
                                                                                                           showFooter={this.showFooter.bind(this)}
                                                                                                           hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                                                           getColumns={this.getColumnNumber.bind(this)}
                                                                                                           hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                           hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="map" path="/projects" render={(props) => <ProjectsMap {...props}
                                                                                                  hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                                                  showPageTransition={this.showPageTransition.bind(this)}
                                                                                                  hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                  hideFooter={this.hideFooter.bind(this)}
                                                                                                  showFooter={this.showFooter.bind(this)}
                                                                                                  hideLoader={this.changeLoaderState.bind(this)}/>}/>

                                <Route key="resources" path="/resources" render={(props) => <Resources {...props}
                                                                                                       hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                       hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="ourprojects" path="/project/:name"
                                       render={(props) => <SingleProjectList {...props}
                                                                             options={this.state.options}
                                                                             hideFooter={this.hideFooter.bind(this)}
                                                                             showFooter={this.showFooter.bind(this)}
                                                                             hideEventsNavigation={this.hideEventsNavigation.bind(this)}
                                                                             getWhiteNav={this.getWhiteNav.bind(this)}
                                                                             getBlackNav={this.getBlackNav.bind(this)}
                                                                             getFullImage={this.getFullImageUrl.bind(this)}
                                                                             getColumns={this.getColumnNumber.bind(this)}
                                                                             hidePageTransition={this.hidePageTransition.bind(this)}
                                                                             hideLoader={this.changeLoaderState.bind(this)}/>}/>
                                <Route key="search" path="/search-results/:key"
                                       render={(props) => <SearchResults {...props}
                                                                         hideFooter={this.hideFooter.bind(this)}
                                                                         showFooter={this.showFooter.bind(this)}
                                                                         options={this.state.options}
                                                                         getColumns={this.getColumnNumber.bind(this)}
                                                                         hidePageTransition={this.hidePageTransition.bind(this)}
                                                                         hideLoader={this.changeLoaderState.bind(this)}/>}/>

                                <Route key="download" path="/download" render={(props) => <ResourcesDownload {...props}
                                                                                                             getColumns={this.getColumnNumber.bind(this)}
                                                                                                             hidePageTransition={this.hidePageTransition.bind(this)}
                                                                                                             hideLoader={this.changeLoaderState.bind(this)}/>}/>

                                <Route key="Page404"
                                       render={(props) => <Page404 getColumns={this.getColumnNumber.bind(this)}
                                                                   getFullImage={this.getFullImageUrl.bind(this)}
                                                                   hidePageTransition={this.hidePageTransition.bind(this)}
                                                                   hideLoader={this.changeLoaderState.bind(this)}/>}/>


                            </Switch>
                            <Footer visible={visibleFooter} options={this.state.options}></Footer>
                        </div>
                    </div>
                </Router>
            </div>

        );
    }
}

export default App;
