import ReactPlayer from 'react-player'
import React, {Component} from "react";

var VideoButton = require('../Assets/video-button.png');

class Video extends Component {

    constructor() {
        super();
        this.state = {
            visible: true,
            playing: false
        }
    }

    hideOverlay() {

        if (this.state.visible) {
            this.setState({visible: false});
            this.setState({playing: true});
        } else {
            this.setState({visible: true});
            this.setState({playing: false});
        }
    }


    render() {
        let {visible, playing} = this.state;
        return (
            <div className={visible ? "video-react-fluid" : "video-react-fluid active"}>
                <div className={visible ? "video-overlay" : "hidden video-overlay"}
                     onClick={this.hideOverlay.bind(this)}></div>
                <div className="video-button-wrapper">
                    <img className={visible ? "video-button" : 'hidden video-button'}
                         alt="video-button"
                         src={VideoButton}/>
                </div>

                <ReactPlayer width="100%" playing={playing}
                             height={window.innerWidth > 1024 ? '360px' : "auto"}
                             url={this.props.url}/>
            </div>


        )
    }

}


export default Video;