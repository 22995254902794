import React, {Component} from 'react';
import CustomModal from "./Modal.jsx";
import NavLink from './NavLink.jsx';
import DelayLink from './Delay.jsx';

var LogoLight = require('../Assets/light_logo.svg');
var LogoMv = require('../Assets/logo_MV.svg');
var LogoMvDark = require('../Assets/dark_mv.svg');


class Footer extends Component {
    constructor() {
        super();
        this.state = {
            sponsors: null,
            icons: null
        };
    }

    componentDidMount() {
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/sponsors', obj)
            .then(response => response.json())
            .then(data => this.setState({sponsors: data.data})).catch(err => console.log(err));

        obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/socialIcons', obj)
            .then(response => response.json())
            .then(data => this.setState({icons: data.data})).catch(err => console.log(err));


    }

    render() {
        let {sponsors, icons} = this.state;
        let options = this.props.options;

        if (this.props.visible) {
            return (
                <div className="footer-section">
                    <div className="footer-wrapper">
                        <div className="row">
                            <div className="col-3 non-clickable"></div>
                            <div className="col-sm-6 col-12 relative">

                                <div className="skewed-box skewed-background">
                                </div>
                                <div className="row">
                                    <div className="col-sm-2 col-0"></div>
                                    <div className="col-sm-5 col-12">
                                        <div className="column-wrapper">
                                            <img className="logo-icon" alt="Logo" src={LogoLight}/>
                                            <span> {options != null ? options.footer_organization_address : ''}</span>
                                            <span> {options != null ? options.footer_organization_address2 : ''}</span>
                                        </div>
                                    </div>
                                    <div className="col-sm-5 col-12 right-section">
                                        {options != null ?
                                            <div className="column-wrapper">
                                                <DelayLink className="policy-link"
                                                           to="policy"
                                                    delay={2000}
                                                    onDelayStart={() => {
                                                        'started'
                                                    }}
                                                    onDelayEnd={() => {
                                                        'ended'
                                                    }}>{options.footer_legal_notices_link}</DelayLink>
                                                <a className="footer-link-desktop" rel="noopener noreferrer"
                                                   href="https://www.molinavisuals.com"><img
                                                    className="small-icon"
                                                    src={LogoMv}
                                                    alt=""/> Made by
                                                    Molina Visuals</a>
                                                <div className="social-icons-holder">
                                                    {icons != null ?
                                                        icons.map((icon) => (
                                                            <NavLink className="social-icon" to={icon.Link}><i style={{color:`${icon.Color}`}}
                                                                className={"fa " + icon.Icon}></i></NavLink>))
                                                        : ""}
                                                </div>
                                            </div>
                                            : ''}

                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-12 right-column">
                                <div className="skewed-box">
                                </div>
                                <div className="column-wrapper">
                                    {sponsors !== null ?
                                        sponsors.map((sponsor, index) => (
                                            <img key={'sponsor' + index} className="logo-dark" alt="Logo"
                                                 src={sponsor.PictureSource}/>)) : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-0">
                            <div className="mobile-column-wrapper">
                                <a className="footer-link" rel="noopener noreferrer"
                                   href="https://www.molinavisuals.com"><img
                                    className="small-icon"
                                    src={LogoMvDark}
                                    alt=""/> Made by
                                    Molina Visuals</a>
                            </div>
                        </div>
                    </div>
                    <CustomModal></CustomModal>
                </div>
            )
        } else {
            return (<div></div>)
        }

    }
};

export default Footer;