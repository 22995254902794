import React, {Component} from 'react';
import General from './General.jsx';
import {withRouter} from "react-router-dom";

class Page404 extends Component {
    constructor() {
        super();
        this.state = {
            builder: null,
            builderSections: null,
        }
    }

    componentDidMount() {
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/page' + window.location.pathname, obj)
            .then(response => response.json())
            .then(data =>
            {this.setState({builder: data});
                if (this.state.builder != null) {

                    if (this.state.builder.data.Active) {
                        this.setState({builderSections: this.state.builder.data});
                    } else if (this.props.location.search == '?preview=1') {
                        this.setState({builderSections: this.state.builder.data});
                    }
                }

            }
            ).catch(err => console.log(err));

        sessionStorage.setItem("fromBlog", 0);
        window.scrollTo(0, 0);
        setTimeout(function () {
            document.getElementById("overlayer").classList.remove('active');
        }, 600);
        setTimeout(() => {
            document.getElementById("preloader").classList.add("hidden");
            document.getElementsByClassName("page-content")[0].classList.add("active");
        }, 3500);
    }
    componentDidUpdate() {
    }

    render() {
        let {builderSections} = this.state;
        if (builderSections === null) {
            return (
                <div className="row">
                    <div className="col-md-4 col-0"></div>
                    <div className="col-12 col-md-8 ">
                        <div className="error-page">
                            <div className="error-text">
                                <h1>No Results Found</h1>
                                <p>The page you requested could not be found. Try refining your search, or use the
                                    navigation above to locate the post.</p>
                            </div>
                        </div>
                    </div>
                </div>)
        } else {
            return (
                <General data={JSON.stringify(builderSections)} hideLoader={this.props.hideLoader.bind(this)}
                         getFullImage={this.props.getFullImage.bind(this)}
                         getColumns={this.props.getColumns.bind(this)}></General>

            )
        }
    }
}


export default withRouter(Page404);