import React, {Component} from 'react';
import Person from "./Person.jsx";
import TextOnlyComponent from "./TextOnlyComponent.jsx";
import Video from "./Video.jsx";
import ReactPlayer from "react-player";
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contactPageData: null,
        };
    }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromBlog",0);
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',


            }
        };

        fetch('https://classicalfutures.eu/api/page/contact', obj)
            .then(response => response.json())
            .then(data => this.setState({contactPageData: data.data})).then(() => this.props.showFooter()).catch(err => console.log(err));

    }
    handleSubmit(e){
        e.preventDefault();
        const data = new FormData(e.target);
        var obj = {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/contact', obj)
            .then(response => response.json())
            .then(data => document.getElementById("contact-form").reset()).catch(err => console.log(err));
    }
    componentWillReceiveProps(nextProps) {

    }

    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
    }

    componentWillUnmount() {
    }

    render() {
        let {contactPageData} = this.state;
        return (
            <div className="page">
                <div className="cutted-background"></div>
                <div className="row">
                    <div className="col-12 col-sm-11">
                        <div className="contact-section">
                            <h1 className='contact-title'>Contact us</h1>
                            {contactPageData !== null ?
                                contactPageData.BuilderDetails.Layouts.map((item) => (
                                    item.Widgets != null ?
                                        item.Widgets.map((widget) => {
                                            switch (widget.Type) {
                                                case 19:
                                                    return   <div
                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}><img src={widget.Options.pictureUrl} /></div>;

                                                case 23:
                                                    return <TextOnlyComponent title={widget.Options.title}
                                                                              description={widget.Options.description}
                                                                              button={widget.Options.url ? 1 : ""}
                                                                              column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                              background={widget.Options.whiteBackground}
                                                                              buttonText={widget.Options.content}/>;
                                                case 25:
                                                    if (widget.Options.videoLink && widget.Options.videoLink !=='') {
                                                        return <div>
                                                            <div className="logo-section">
                                                                <div className="row">
                                                                    <div
                                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <div className="small-skewed-box"></div> : ''}
                                                                        <div className="logo-skewed-clear">
                                                                            {widget.Options.pictureUrl != '' ?
                                                                                <img className="logo-about wow slideInRight"
                                                                                     data-wow-duration="2s" alt="Logo"
                                                                                     src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                                <div
                                                                                    className="section-title">{widget.Options.title}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="video-section video-mobile">
                                                                <div className="row">
                                                                    <div
                                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                        <div className="row">
                                                                            <div className="col-md-5 col-12 video-text">
                                                                                <div className="small-skewed-box"></div>
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                            </div>
                                                                            <div className="col-md-7 col-12 wow slideInRight"
                                                                                 data-wow-duration="2s">
                                                                                <Video url={widget.Options.videoLink}></Video>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            ;
                                                    } else {
                                                        return <div className="video-section">
                                                            <div className="logo-section">
                                                                <div className="row">
                                                                    <div className="col-md-4 col-0"></div>
                                                                    <div
                                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <div className="small-skewed-box"></div> : ''}
                                                                        <div className="logo-skewed-clear">
                                                                            {widget.Options.pictureUrl != '' ?
                                                                                <img className="logo-about wow slideInRight"
                                                                                     data-wow-duration="2s" alt="Logo"
                                                                                     src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                                <div
                                                                                    className="section-title">{widget.Options.title}</div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 video-text">
                                                                            <div className="small-skewed-box"></div>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                        </div>
                                                                        <div className="col-md-7 col-12">
                                                                            <div className="image-wrapper">
                                                                                { widget.Options.media ? <div className="article-image wow slideInRight"
                                                                                                              data-wow-duration="2s"
                                                                                                              style={{backgroundImage: `url( ${this.props.getFullImage(widget.Options.media[0].pictureUrl)})`}}></div> : "" }"
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            ;
                                                    }
                                                case 27:
                                                    return <div className="form-section">

                                                        <form onSubmit={this.handleSubmit.bind(this)} id="contact-form">
                                                            <div className="row">
                                                                <div className="col-sm-5 col-12">
                                                                    <input type="text" id="name" required="required"
                                                                           name="name"
                                                                           placeholder={widget.Options.name}/>
                                                                </div>
                                                                <div className="col-sm-5 col-12">
                                                                    <input type="email" id="email" required="required"
                                                                           name="email"
                                                                           placeholder={widget.Options.email}/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-10">
                                                                    <input className="message" required="required"
                                                                           type="text" id="message" name="message"
                                                                           placeholder={widget.Options.message}/>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 col-12">
                                                                    <label
                                                                        className="privacy-policy">{widget.Options.title}</label>
                                                                    <input className="checkbox" required="required"
                                                                           type="checkbox" name="checkbox"
                                                                           id="checkbox"/><label
                                                                    className="checkbox-label"
                                                                    dangerouslySetInnerHTML={{__html: widget.Options.description}}></label>

                                                                </div>
                                                                <div className="col-sm-4 col-12">
                                                                    <div className="box-button">
                                                                        <button
                                                                            className="button-submit button-skewed-clear"
                                                                            type="submit">{widget.Options.content}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>;
                                                case 10:
                                                    return <Person name={widget.Options.name}
                                                                   description={widget.Options.description}
                                                                   position={widget.Options.position}
                                                                   image={ widget.Options.pictureUrl ?  this.props.getFullImage(widget.Options.pictureUrl) :""}></Person>;
                                                case 16:
                                                    return <div className="row">
                                                        <div className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                            <div className="video-wrapper">
                                                                <ReactPlayer width="100%" url={widget.Options.content}/>
                                                            </div></div></div>;
                                                default:


                                            }
                                        }) : ''

                                )) : ''}

                                <div className="row">
                                    <div className="col-sm-11 col-12 right-section">
                                        <div className="contact-logo wow slideInRight"
                                             data-wow-duration="1s">
                                            <div className="small-skewed-box no-image-box"></div>
                                            <div className="small-skewed-box">
                                                <img className='contact-logo-circles'
                                                     src={process.env.PUBLIC_URL + 'cfe_contact.png'}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>
                    </div>


                </div>
            </div>

        )
    }

}


export default Contact;