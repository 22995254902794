import React, {Component} from 'react';
import Dropdown from './Dropdown.jsx';
import BlogPost from "./BlogPost";
import TextOnlyComponent from './TextOnlyComponent.jsx';
import Video from "./Video.jsx";
import Person from "./Person.jsx";
import ReactPlayer from "react-player";
var ImageRight = require('../Assets/cfe_logo_right.svg');
var ImageLeft = require('../Assets/cfe_logo_left.svg');

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: null,
            posts: null,
            builderSections: null
        };
    }

    componentDidMount() {
        this.props.hideFooter();
        window.scrollTo(0, 0);
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);

        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',


            }
        };

        fetch('https://classicalfutures.eu/api/page/blog', obj)
            .then(response => response.json())
            .then(data => this.setState({builderSections: data.data})).catch(err => console.log(err));

        obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',


            }
        };

        fetch('https://classicalfutures.eu/api/blog', obj)
            .then(response => response.json())
            .then(data => this.setState({posts: data.data})).catch(err => console.log(err));

        obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',

            }
        };


        fetch('https://classicalfutures.eu/api/categories', obj)
            .then(response => response.json())
            .then(data => {
                this.setState({categories: data.data.map(item => [item.CategoryId, item.Details.Name])});
            }).then(() => this.props.showFooter()).catch(err => console.log(err));

    }

    getPostsForCategory(categoryId) {
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/blog?categoryId=' + categoryId, obj)
            .then(response => response.json())
            .then(data => this.setState({posts: data.data})).catch(err => console.log(err));

    }

    updateDimensions() {
        if (window.innerWidth > 769) {
            var el = document.getElementById("mask-inner-blog");
            var left = window.innerWidth - window.innerHeight * 6 / 10 - 330;
            el.setAttribute("x", left + 'px');
        }
    }

    componentWillReceiveProps(nextProps) {
    }

    componentWillUpdate(nextProps, nextState) {
    }

    componentDidUpdate(prevProps, prevState) {
        sessionStorage.setItem("fromBlog",1);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        let {posts, categories, builderSections} = this.state;
        let options = this.props.options;
        return (
            <div className="page">
                <div className="cutted-background"></div>
                <div className="row">
                    <div className={categories != null ? "col-12 dropdown-wrapper-desktop" : "col-12 dropdown-wrapper-desktop hidden"}>
                        <div className="svg-blog">
                            <svg className="masked-element-blog" width="100%" height="350px">
                                <svg width="300%" height="350px">
                                    <rect className="rectangle-background" x="0" y="0" width="100%" height="350px"
                                          fill="white"/>
                                </svg>
                            </svg>
                            <div className="arrow-down"></div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="svg-mask" width="100%">
                                <defs>
                                    <mask id="mask-blog">
                                        <svg width="100%" height="350px" x="calc(100% - 330px)" id="mask-inner-blog">
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-240px"
                                                   xlinkHref={ImageRight}>
                                            </image>
                                            <circle cx="175" cy="175" r="175" fill="white"/>
                                            <image className="image-chunk" id="animation-about-right" width="350px"
                                                   height="350px" x="-120px"
                                                   xlinkHref={ImageLeft}>
                                            </image>
                                        </svg>
                                    </mask>
                                </defs>
                            </svg>
                        </div>
                        {categories ? <Dropdown type="Categories" items={categories}
                                                clickFunction={this.getPostsForCategory.bind(this)}/> : ''}
                    </div>
                </div>

                <div className="blog-section">
                    <div>
                        {builderSections !== null ?
                            builderSections.BuilderDetails.Layouts.map((item) => (
                                item.Widgets != null ?
                                    item.Widgets.map((widget) => {
                                        switch (widget.Type) {
                                            case 19:
                                                return   <div
                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}><img src={widget.Options.pictureUrl} /></div>;

                                            case 16:
                                                return <div className="row">
                                                    <div className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                        <div className="video-wrapper">
                                                            <ReactPlayer width="100%" url={widget.Options.content}/>
                                                        </div></div></div>;
                                            case 10:
                                                return <Person name={widget.Options.name}
                                                               description={widget.Options.description}
                                                               position={widget.Options.position}
                                                               image={ widget.Options.pictureUrl ?  this.props.getFullImage(widget.Options.pictureUrl) :""}></Person>;
                                            case 23:
                                                return <TextOnlyComponent title={widget.Options.title}
                                                                          column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                          description={widget.Options.description}
                                                                          button={widget.Options.url ? 1 : ""}
                                                                          buttonText={widget.Options.content}
                                                                          background={0}/>;
                                            case 25:
                                                if (widget.Options.videoLink && widget.Options.videoLink !=='') {
                                                    return <div>
                                                        <div className="logo-section">
                                                            <div className="row">
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <div className="small-skewed-box"></div> : ''}
                                                                    <div className="logo-skewed-clear">
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <img className="logo-about wow slideInRight"
                                                                                 data-wow-duration="2s" alt="Logo"
                                                                                 src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                            <div
                                                                                className="section-title">{widget.Options.title}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="video-section video-mobile">
                                                            <div className="row">
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    <div className="row">
                                                                        <div className="col-md-5 col-12 video-text">
                                                                            <div className="small-skewed-box"></div>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                        </div>
                                                                        <div className="col-md-7 col-12 wow slideInRight"
                                                                             data-wow-duration="2s">
                                                                            <Video url={widget.Options.videoLink}></Video>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        ;
                                                } else {
                                                    return <div className="video-section">
                                                        <div className="logo-section">
                                                            <div className="row">
                                                                <div className="col-md-4 col-0"></div>
                                                                <div
                                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <div className="small-skewed-box"></div> : ''}
                                                                    <div className="logo-skewed-clear">
                                                                        {widget.Options.pictureUrl != '' ?
                                                                            <img className="logo-about wow slideInRight"
                                                                                 data-wow-duration="2s" alt="Logo"
                                                                                 src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                            <div
                                                                                className="section-title">{widget.Options.title}</div>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                <div className="row">
                                                                    <div className="col-md-5 col-12 video-text">
                                                                        <div className="small-skewed-box"></div>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                    </div>
                                                                    <div className="col-md-7 col-12">
                                                                        <div className="image-wrapper">
                                                                            { widget.Options.media ? <div className="article-image wow slideInRight"
                                                                                                          data-wow-duration="2s"
                                                                                                          style={{backgroundImage: `url( ${this.props.getFullImage(widget.Options.media[0].pictureUrl)})`}}></div> : "" }"
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                        ;
                                                }
                                            case 26:
                                                return posts != null ?
                                                    posts.map((post, ind) => (
                                                        <div>
                                                            {ind === 0 ?
                                                            <div className="row">
                                                                <div className="col-12 dropdown-wrapper">
                                                                    {categories ?
                                                                        <Dropdown type="Categories" items={categories}
                                                                                  clickFunction={this.getPostsForCategory.bind(this)}/> : ''}
                                                                </div>
                                                            </div> : ''}
                                                            <BlogPost key={"post-" + ind} button="1"   hideEventsNavigation={this.props.hideEventsNavigation}
                                                                      link={"/blog-post/" + post.Details.Slug} buttonText={options.blog_button_text}
                                                                      image={post.Picture!= null ? post.PictureSource : 0}
                                                                      title={post.Details.Title}
                                                                      description={post.Details.Description}></BlogPost></div>
                                                    )) : '';
                                            default:


                                        }
                                    }) : ''

                            )) : ''}
                    </div>

                </div>
            </div>

        )
    }

}


export default Blog;