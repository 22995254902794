import React, {Component} from 'react';
import {ReactComponent as Logo} from '../Assets/cfe_logo_final.svg';
import NavLink from './NavLink.jsx';

class ResourcesDownload extends Component {
    constructor() {
        super();
        this.state = {
            resources: null
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        setTimeout(() => {
            this.props.hideLoader();
        }, 2500);
        sessionStorage.setItem("fromBlog",0);
        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/resources', obj)
            .then(response => response.json())
            .then(data => this.setState({resources: data.data})).catch(err => console.log(err));


    }

    componentWillMount() {
        if (localStorage.getItem('user_login') != '1') {
            this.props.history.push('/resources');
        }
    }

    render() {
        let {resources} = this.state;
        return (
            <div>
                <div className="cutted-background"></div>
                <div className="download-section page">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h1>Resources</h1>
                            </div>
                        </div>
                    </div>
                    {resources != null ?
                        resources.map((item) => (
                            <div className="row">
                                <div className="col-xl-5 col-sm-8 col-12 resources-text">
                                    <p>{item.Details.Description}</p>
                                    <div className="box-button download-button">
                                        <a download href={item.FileUrl}>
                                            <button>
                                                <div className="button-skewed-clear">{item.Details.LinkText}</div>
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        ))
                        : ''}
                </div>
            </div>

    )
    }

}


export default ResourcesDownload;