import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

/**
 * Wraps the React Router Link component and creates a delay after the link is clicked.
 */
export default class DelayLink extends React.Component {
    static propTypes = {
        /**
         * Milliseconds to wait before registering the click.
         */
        delay: PropTypes.number,
        /**
         * Called after the link is clicked and before the delay timer starts.
         */
        onDelayStart: PropTypes.func,
        /**
         * Called after the delay timer ends.
         */
        onDelayEnd: PropTypes.func
    };

    static defaultProps = {
        delay: 0,
        onDelayStart: () => {
        },
        onDelayEnd: () => {
        }
    };

    static contextTypes = Link.contextTypes;

    constructor(props) {
        super(props);
        this.timeout = null;
    }

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    /**
     * Called when the link is clicked
     *
     * @param {Event} e
     */
    handleClick = (e) => {
        const {replace, to, delay, onDelayStart, onDelayEnd} = this.props;
        const {history} = this.context.router;

        onDelayStart(e, to);
        if (this.context.router.route.location.pathname !== this.props.to) {
            document.getElementById("overlayer").classList.add('active');
            if(this.props.to != "/projects" && this.props.to != "/projects-list") {
                if(this.props.hideEventsNavigation) {this.props.hideEventsNavigation(true,0);}
            }
            setTimeout(function () {
                document.getElementsByClassName("page-content")[0].classList.remove("active");
                document.getElementsByTagName("body")[0].classList.remove("active");
                window.scrollTo(0, 0);
            }, 2000);

        }
        if (e.defaultPrevented) {
            return;
        }
        e.preventDefault();
        if (this.context.router.route.location.pathname !== this.props.to) {
            this.timeout = setTimeout(() => {
                if (replace) {
                    history.replace(to);
                } else {
                    history.push(to);
                }
                onDelayEnd(e, to);
                if (window.innerWidth < 1024) {
                    if (this.props.hideSidebar) {
                        setTimeout(this.props.hideSidebar(), 3400);
                    }
                }
                setTimeout(function () {
                    document.getElementById("overlayer").classList.remove('active');
                    document.getElementsByClassName("page-content")[0].classList.add("active");
                    document.getElementsByTagName("body")[0].classList.add("active");
                }, 0);

                var el = '';

                if (window.innerWidth > 769) {
                    el = document.getElementById("active-sidebar-link");
                } else {
                    el = document.getElementById("active-sidebar-link-mobile");
                }

                if (el !== null) {
                    setTimeout(function () {
                        if (document.getElementsByClassName("sidebar").length > 0) {
                            var sidebar = document.getElementsByClassName("sidebar")[0];
                            if (sidebar.querySelector('.active-page') != null) {
                                var active = sidebar.querySelector('.active-page').innerHTML;
                                if (document.querySelector('.active-page').getAttribute("href") !== '/' && sidebar.querySelector('.active-page').getAttribute("data-href") !== 'resources' && document.querySelector('.active-page').getAttribute("data-href") !== 'policy' && document.querySelector('.active-page').getAttribute("data-href") !== 'policy') {
                                    el.innerHTML = active;
                                }

                            }else {
                                el.innerHTML = '';
                            }
                        }
                    }, 350);
                }
            }, delay);
        }

    };

    render() {
        var isActive = this.context.router.route.location.pathname === this.props.to;
        if (this.context.router.route.location.pathname == '/projects-list' && this.props.to == "/projects") {
            isActive = 1;
        }
        if (this.context.router.route.location.pathname.match(/project/) && this.props.to == "/projects") {
            isActive = 1;
        }
        if (this.context.router.route.location.pathname.match(/blog-post/) && this.props.to == "/blog") {
            isActive = 1;
        }
        var className = isActive ? 'active-page' : '';
        const props = Object.assign({}, this.props);
        delete props.delay;
        delete props.onDelayStart;
        delete props.onDelayEnd;

        return (
            <Link className={className} {...props} onClick={this.handleClick}>{this.props.children}</Link>
        );
    }
}