import React, {Component} from 'react';
import Carousel from './Carousel.jsx';
import {Button} from 'react-bootstrap';
import TextOnlyComponent from "./TextOnlyComponent";
import DelayLink from './Delay.jsx';
import Video from "./Video.jsx"
import Person from "./Person.jsx";
import ReactPlayer from "react-player";

var ImageCenter = require('../Assets/cfe_logo_center.svg');
var ImageRight = require('../Assets/cfe_logo_right.svg');
var ImageLeft = require('../Assets/cfe_logo_left.svg');

class Home extends Component {
    constructor() {
        super();
        if (window.innerWidth > 768) {
            this.state = {
                number: Math.floor(Math.random() * 3),
                windowHeight: window.innerHeight * 11 / 12 + 'px',
                doubleHeight: 2 * window.innerHeight * 11 / 12 + 'px',
                x: window.innerHeight + 'px',
                builderSections: null,
                projectLogos: null
            }
        } else {
            this.state = {
                number: Math.floor(Math.random() * 3),
                windowHeight: window.innerWidth - 50 + 'px',
                doubleHeight: window.innerWidth + 'px',
                x: window.innerWidth / 3 + 'px',
                builderSections: null,
                projectLogos: null
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        sessionStorage.setItem("fromBlog", 0);
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);


        window.addEventListener("resize", this.updateDimensions.bind(this));

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',
            }
        };

        fetch('https://classicalfutures.eu/api/page/home', obj)
            .then(response => response.json())
            .then(data => this.setState({builderSections: data.data})).catch(err => console.log(err));

        obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs',

            }
        };

        fetch('https://classicalfutures.eu/api/projects', obj)
            .then(response => response.json())
            .then(data => this.setState({
                projectLogos: data.data.map(item => [{
                    url: "https://classicalfutures.eu/cdn/Media/Projects/Logo/Black/" + item.LogoBlack,
                    link: "/project/" + item.Details.Slug
                }])
            })).then(() => this.updateDimensions()).catch(err => console.log(err));
    }

    componentDidUpdate() {
        if (this.state.builderSections != null) {
            if (window.innerWidth > 769 && (window.navigator.userAgent.indexOf("Edge") <= -1)) {
                document.getElementById("svg-home").classList.remove("hidden");
            } else {
                document.getElementsByClassName("svg-animation")[0].classList.remove("hidden");
            }
            if (window.navigator.userAgent.indexOf('Trident/') > -1) {
                document.getElementById("svg-home").classList.add("explorer");
            }
            let PictureLeft = document.getElementById("animation-right");
            let PictureRight = document.getElementById("animation-left");
            let movit = 0;
            let mouseposition = 0;
            let maskposition1 = 0;
            var mousePosition;
            let maskposition2 = 0;
            var offset = [0, 0];
            if (window.innerWidth > 768) {
                document.addEventListener('mousemove', function (event) {
                    if (window.navigator.userAgent.indexOf("Edge") > -1) {
                        event.preventDefault();

                        mousePosition = {

                            x: event.clientX,
                            y: event.clientY

                        };

                        var X = (window.innerWidth - (mousePosition.x + offset[0])) / 2 - 150 + 'px';
                        var XL = (window.innerWidth - (mousePosition.x + offset[0])) / 2 - 150 + 'px';

                        if (mousePosition.x < window.innerWidth - 300 && mousePosition.x > window.innerWidth / 2) {
                            var Xnegative = '-' + X;
                            PictureLeft.setAttribute("x", Xnegative);
                            PictureRight.setAttribute("x", XL);
                        } else {
                            if (mousePosition.x > window.innerWidth - 300) {
                                PictureLeft.setAttribute("x", 0);
                                PictureRight.setAttribute("x", 0);
                            }
                        }

                    } else {

                        var ampl = window.innerWidth / 100 * 85;

                        mouseposition = event.clientX;
                        movit = 30 / ampl;
                        maskposition1 = mouseposition * movit;
                        maskposition1 = maskposition1 + 90;
                        if (maskposition1 > 120) {
                            maskposition1 = 120;
                        }

                        movit = 30 / ampl;
                        maskposition2 = mouseposition * movit;
                        maskposition2 = 150 - maskposition2;
                        if (maskposition2 < 120) {
                            maskposition2 = 120;
                        }
                        if (document.getElementById("svg-home")) {
                            document.getElementById("svg-home").style.webkitMaskPosition = maskposition1 + "% -50px, 120% -50px, " + maskposition2 + "% -50px";
                        }
                    }
                }, true);
            } else {
                PictureLeft.setAttribute("x", -window.innerWidth / 7 + "px");
                PictureRight.setAttribute("x", window.innerWidth / 7 + "px");
            }
        }
    }


    updateDimensions() {
        let PictureLeft = document.getElementById("animation-right");
        let PictureRight = document.getElementById("animation-left");
        if (window.innerWidth > 768) {
            this.setState({
                windowHeight: window.innerHeight * 11 / 12 + 'px',
                doubleHeight: 2 * window.innerHeight * 11 / 12 + 'px',
                x: window.innerHeight + 'px',
            });
        } else {
            this.setState({
                windowHeight: window.innerWidth - 50 + 'px',
                doubleHeight: window.innerWidth + 'px',
                x: window.innerWidth / 3 + 'px'
            });
            if (PictureLeft) {
                PictureLeft.setAttribute("x", -window.innerWidth / 7 + "px");
                PictureRight.setAttribute("x", window.innerWidth / 7 + "px");
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        let {windowHeight, doubleHeight, x, builderSections, projectLogos, number} = this.state;
        let width = window.innerWidth;
        return (
            <div className="home-section">
                {builderSections !== null ?
                    builderSections.BuilderDetails.Layouts.map((item) => (
                        item.Widgets != null ?
                            item.Widgets.map((widget) => {
                                switch (widget.Type) {
                                    case 20:
                                        return <div className="intro-section">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="title-description">
                                                        {widget.Options.title ?
                                                            <h1 className="page-title">{widget.Options.title}</h1> : ""}
                                                        {widget.Options.description ? <div
                                                            className="intro-description">{widget.Options.description}</div> : ''}
                                                    </div>
                                                    <div id="svg-home" className="hidden"
                                                         style={{backgroundImage: `url( ${widget.Options.media && widget.Options.media[parseInt(number)] ? this.props.getFullImage(widget.Options.media[parseInt(number)].pictureUrl) : this.props.getFullImage(widget.Options.media[0].pictureUrl) })`}}></div>
                                                    <div className="svg-animation hidden">
                                                        <svg className="masked-element " width={doubleHeight}
                                                             height={windowHeight}>
                                                            {widget.Options.media[parseInt(number)].pictureUrl ? "" : number = 0}
                                                            <image
                                                                preserveAspectRatio={width > 1024 ? "xMaxYMax" : "none"}
                                                                xlinkHref={widget.Options.media ? this.props.getFullImage(widget.Options.media[parseInt(number)].pictureUrl) : ''}
                                                                width={doubleHeight} height={windowHeight}/>
                                                        </svg>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="svg-mask">
                                                            <defs>
                                                                <mask id="mask1">
                                                                    <svg width={doubleHeight} height={windowHeight}
                                                                         x={x}>
                                                                        <image className="image-chunk"
                                                                               id="animation-right" width={windowHeight}
                                                                               height={windowHeight}
                                                                               xlinkHref={ImageRight}>
                                                                        </image>
                                                                        <image width={windowHeight}
                                                                               height={windowHeight}
                                                                               xlinkHref={ImageCenter}></image>
                                                                        <image className="image-chunk"
                                                                               id="animation-left" width={windowHeight}
                                                                               height={windowHeight}
                                                                               xlinkHref={ImageLeft}></image>
                                                                    </svg>
                                                                </mask>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>;
                                    default:
                                        return '';

                                }
                            }) : ''

                    )) : ''}
                <div className="page">
                    {builderSections !== null ?
                        builderSections.BuilderDetails.Layouts.map((item) => (
                            item.Widgets != null ?
                                item.Widgets.map((widget) => {
                                    switch (widget.Type) {
                                        case 22:
                                            return <div className="partners-section">
                                                <div className="row">
                                                    <div
                                                        className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                        <div className="box-wrapper">

                                                            <div className="relative">
                                                                <DelayLink
                                                                    to="projects-list"
                                                                    delay={2000}
                                                                    onDelayStart={() => {
                                                                        'started'
                                                                    }}
                                                                    onDelayEnd={() => {
                                                                        'ended'
                                                                    }}> <Button className="box-button">
                                                                    <div className="button-skewed-clear">
                                                                        {widget.Options.content}
                                                                    </div>
                                                                </Button>
                                                                    <div className="skewed-box"></div>
                                                                </DelayLink>

                                                                <div
                                                                    className="section-title">{widget.Options.title}</div>

                                                            </div>
                                                        </div>
                                                        <div className="slider-section">
                                                            {projectLogos != null ?
                                                                <Carousel rows="2" number="4" effect="none"
                                                                          images={projectLogos}
                                                                          hideSwipe="true"></Carousel> : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>;
                                        case 19:
                                            return <div
                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                <img src={widget.Options.pictureUrl}/></div>;

                                        case 23:
                                            return <TextOnlyComponent title={widget.Options.title}
                                                                      description={widget.Options.description}
                                                                      button={widget.Options.url ? 1 : ""}
                                                                      column={this.props.getColumns(item.LayoutTypeId, widget.Weight)}
                                                                      route={widget.Options.url}
                                                                      background={widget.Options.whiteBackground}
                                                                      buttonText={widget.Options.content}/>;
                                        case 25:
                                            if (widget.Options.videoLink && widget.Options.videoLink !== '') {
                                                return <div>
                                                    <div className="logo-section">
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                {widget.Options.pictureUrl != '' ?
                                                                    <div className="small-skewed-box"></div> : ''}
                                                                <div className="logo-skewed-clear">
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <img className="logo-about wow slideInRight"
                                                                             data-wow-duration="2s" alt="Logo"
                                                                             src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                        <div
                                                                            className="section-title">{widget.Options.title}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="video-section video-mobile">
                                                        <div className="row">
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                <div className="row">
                                                                    <div className="col-md-5 col-12 video-text">
                                                                        <div className="small-skewed-box"></div>
                                                                        <div
                                                                            dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                    </div>
                                                                    <div className="col-md-7 col-12 wow slideInRight"
                                                                         data-wow-duration="2s">
                                                                        <Video url={widget.Options.videoLink}></Video>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    ;
                                            } else {
                                                return <div className="video-section">
                                                    <div className="logo-section">
                                                        <div className="row">
                                                            <div className="col-md-4 col-0"></div>
                                                            <div
                                                                className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                                {widget.Options.pictureUrl != '' ?
                                                                    <div className="small-skewed-box"></div> : ''}
                                                                <div className="logo-skewed-clear">
                                                                    {widget.Options.pictureUrl != '' ?
                                                                        <img className="logo-about wow slideInRight"
                                                                             data-wow-duration="2s" alt="Logo"
                                                                             src={this.props.getFullImage(widget.Options.pictureUrl)}/> :
                                                                        <div
                                                                            className="section-title">{widget.Options.title}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div
                                                            className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                            <div className="row">
                                                                <div className="col-md-5 col-12 video-text">
                                                                    <div className="small-skewed-box"></div>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{__html: widget.Options.description}}></div>
                                                                </div>
                                                                <div className="col-md-7 col-12">
                                                                    <div className="image-wrapper">
                                                                        {widget.Options.media ? <div
                                                                            className="article-image wow slideInRight"
                                                                            data-wow-duration="2s"
                                                                            style={{backgroundImage: `url( ${this.props.getFullImage(widget.Options.media[0].pictureUrl)})`}}></div> : ""}"
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                    ;
                                            }
                                        case 10:
                                            return <Person name={widget.Options.name}
                                                           description={widget.Options.description}
                                                           position={widget.Options.position}
                                                           image={widget.Options.pictureUrl ? this.props.getFullImage(widget.Options.pictureUrl) : ""}></Person>;
                                        case 16:
                                            return <div className="row">
                                                <div
                                                    className={`col-12 col-md-${this.props.getColumns(item.LayoutTypeId, widget.Weight)}`}>
                                                    <div className="video-wrapper">
                                                        <ReactPlayer width="100%" url={widget.Options.content}/>
                                                    </div>
                                                </div>
                                            </div>;
                                        default:
                                            return '';

                                    }
                                }) : ''

                        )) : ''}

                </div>

            </div>


        );
    }
}


export default Home;