import React, {Component} from 'react';
import DelayLink from './Delay.jsx';
import {ReactComponent as Logo} from "../Assets/cfe_logo_final.svg";

class Resources extends Component {
    constructor() {
        super();
        this.state = {
            options: null
        };
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        if (localStorage.getItem("user_login") == '1') {
            this.props.history.push('/download');
        } else {
            document.getElementsByClassName("close-icon")[0].click();
        }
        sessionStorage.setItem("fromBlog",0);
        setTimeout(() => {
            this.props.hideLoader();
        }, 2800);

        var obj = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VybmFtZSI6ImNmZSJ9.jSodJX3SrMou2hU3rQgypqLLCsd5WCbg8MiJFTsHdrs'
            }
        };

        fetch('https://classicalfutures.eu/api/site-options', obj)
            .then(response => response.json())
            .then(data => this.setState({options: data.data})).catch(err => console.log(err));
    }

    logIn() {
        let {options} = this.state;
        var val = document.getElementById("downloadCode").value;
        console.log(val);
        if (val !== '' && this.state.options != null) {
            if (val == options.resource_download_code) {
                document.getElementById("overlayer").classList.add('active');
                document.getElementById("wrong-msg").classList.remove("active-msg");
                setTimeout(() => {
                    localStorage.setItem('user_login', 1);
                    this.props.history.push('/download');
                    document.getElementById("overlayer").classList.remove('active');
                }, 2500);

            } else {
                document.getElementById("wrong-msg").classList.add("active-msg");
            }
        }
    }

    componentWillUnmount() {
        document.getElementById("wrong-msg").classList.remove("active-msg");
    }

    render() {
        return (

            <div className="resources">
                <div className="cutted-background"></div>
                <div className="row">
                    <div className="col-md-4 col-12"></div>
                    <div className="col-md-8 col-12">
                        <div className='we-are-section'>
                            <div className="box-wrapper">
                                <div className="skewed-box">
                                </div>
                                <div className="box-button">
                                    <button onClick={this.logIn.bind(this)}
                                            className="button-submit button-skewed-clear"
                                            type="submit">Submit
                                    </button>
                                </div>
                            </div>
                            <div className="resources-title">To download resources log in:</div>
                            <input type="text" required="required" id="downloadCode" className="download"></input>
                            <div id="wrong-msg" className="wrong-msg">Wrong code!</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Resources;
